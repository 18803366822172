import { MATCH } from '../constants'
import md5 from 'md5'

export default {
  log(message) {
    // Logs a console message for dev environments and takes care of disabling linting
    if (process.env.NODE_ENV === 'development') {
      /*eslint-disable */
      console.log(message)
      /*eslint-enable */
    }
  },

  isArray(value) {
    return (
      value !== null && typeof value === 'object' && value.length !== undefined
    )
  },

  isObject(value) {
    return value !== null && typeof value === 'object'
  },

  isEmpty(value) {
    // Note that a value containing only spaces is counted as empty
    return (
      value === null ||
      value === undefined ||
      String(value).trim() === '' ||
      (typeof value === 'object' && Object.keys(value).length === 0)
    )
  },

  clone(obj) {
    var clone = JSON.stringify(obj)
    return JSON.parse(clone)
  },

  removeKeys(obj, valueToRemove) {
    // Remove keys of a specified value from an object
    Object.keys(obj).forEach((key) => {
      if (obj[key] === valueToRemove) {
        delete obj[key]
      }
    })
    return obj
  },

  isMatch(val1, val2, matching) {
    /*
      Returns true if values match. Handles all data types including object comparison
        val1 <any datatype>   - Required: First comparison value
        val2 <any datatype>   - Required: Second comparison value
        matching <string>|<json> - Optional: See below

          <string>: Specifies a matching mode using the MATCH constant (e.g. MATCH.INSENSITIVE)
                    Not applicable when comparing objects.

          <json>:   Specifies matching criteria, i.e. which fields to match and what matching
                    to use. val1 & val2 must be json containing the keys in the criteria. E.g.
                    {
                      someFieldName: MATCH.EXACT,
                      anotherFieldName: MATCH.INSENSITIVE
                    }
    */

    if (this.isObject(matching)) {
      // If matching criteria has been passed in, match on multiple fields...
      let isMatch = true
      Object.keys(matching).forEach((key) => {
        if (!this.isMatch(val1[key], val2[key], matching[key])) {
          isMatch = false
        }
      })
      return isMatch
    } else if (this.isObject(val1)) {
      // If comparison values are objects, perform object comparison...
      return JSON.stringify(val1) === JSON.stringify(val2)
    } else if (matching === MATCH.INSENSITIVE) {
      return val1.toLowerCase() === val2.toLowerCase()
    } else if (matching === MATCH.FUZZY_CONTACT) {
      return this.isFuzzyContact(val1, val2)
    } else if (matching === MATCH.FUZZY) {
      return this.isFuzzyMatch(val1, val2)
    }
    return val1 === val2
  },

  isFuzzyMatch(val1, val2) {
    // Loosely compares two strings by ignoring case, whitespace and symbols.
    return clean(val1) === clean(val2)

    function clean(value) {
      if (typeof value == 'string') {
        return value.toLowerCase().replace(/\W/g, '')
      }
      return value
    }
  },

  isFuzzyMatchEmail(val1, val2) {
    // Loosely compares two strings by ignoring case.
    return clean(val1) === clean(val2)

    function clean(value) {
      if (typeof value == 'string') {
        return value.toLowerCase()
      }
      return value
    }
  },

  isFuzzyContact(val1, val2) {
    // Loosely compares two strings by ignoring case, whitespace and symbols.
    return clean(val1) === clean(val2)

    function clean(value) {
      if (value) {
        if (value.match(/[a-z]/i)) {
          return value.toLowerCase()
        } else if (value.replace(/\W/g, '').startsWith('02')) {
          return value.replace(/\W/g, '').substring(2)
        } else if (value.replace(/\W/g, '').startsWith('0')) {
          return value.replace(/\W/g, '').substring(1)
        } else if (value.replace(/\W/g, '').startsWith('612')) {
          return value.replace(/\W/g, '').substring(3)
        } else if (value.replace(/\W/g, '').startsWith('61')) {
          return value.replace(/\W/g, '').substring(2)
        }
        return value.replace(/\W/g, '')
      }
      return ''
    }
  },

  sanitize(userText) {
    // Neutralises any malicious user content by removing '<' chrs if
    // the string contains both '<' and '>' chrs. If there is a valid
    // use-case for user content to contain both these characters, then
    // this function should not be used in these cases.
    if (userText.indexOf('<') >= 0 && userText.indexOf('>') > 0) {
      return userText.replace(/</g, '')
    }
    return userText
  },

  trimFrom(sourceText, searchString, useLastOf, offset) {
    // Trims content from the end of a string, starting at the index of searchString

    // UTILS.trim('allergies[0].medications[0].dosage', 'FROM', '.', true)
    // Returns: 'allergies[0].medications[0]'

    // sourceText - String to be trimmed
    // searchString - String to search for
    // useLastOf - Optional bool: If true, use the last occurance of searchString
    // offset - Optional int: Moves the trim position
    var index = useLastOf
      ? sourceText.lastIndexOf(searchString)
      : sourceText.indexOf(searchString)
    if (index === -1) {
      return sourceText
    }
    return sourceText.substr(0, index + (offset || 0))
  },

  getListDivider(list) {
    // Gets a string to be used as a list divider, based on the
    // current longest value in the list.
    var maxItemLength = this.maxListItemLength(list, 'text')
    return '—'.repeat(maxItemLength / 2)
  },

  maxListItemLength(jsonArray, jsonKey) {
    // Iterates through a JSON array and calculates the longest string used
    // for a given column (specified by jsonKey)
    return Math.max(
      ...jsonArray.map((el) => {
        try {
          return el[jsonKey].length
        } catch (e) {
          return 0
        }
      })
    )
  },

  isChildWindow() {
    try {
      return window.opener.location.hostname === window.location.hostname
    } catch (e) {
      return false
    }
  },

  toFullNameString(...names) {
    return names.join(' ').trim()
  },
  hasArrayWithValues(obj) {
    let arrays = Object.values(obj)
    const reducer = (acc, cur) => acc + cur.length
    return arrays.reduce(reducer, 0)
  },
  md5Hash(str) {
    return str ? md5(str) : null
  },
  isValidPhoneNumber(val) {
    return /^[0-9 ()+-]+$/.test(val)
  },
  isNextCalendarYear7App(application, oesProperties) {
    if (!application || !oesProperties) {
      return false
    }
    const activeEOICalendarYear =
      oesProperties.y67application?.activeEOICalendarYear

    return (
      application.scholasticYear === '7' &&
      application.intendedStartDate?.split('-')[0] === activeEOICalendarYear
    )
  },
  isNextCalendarYear7LocalApp(application, oesProperties) {
    return (
      this.isNextCalendarYear7App(application, oesProperties) &&
      application?.isInArea === true &&
      !application?.eoiID &&
      (!application?.enrolmentType || application?.enrolmentType === 'CORE')
    )
  },
  isNextCalendarYear7OoaApp(application, oesProperties) {
    return (
      this.isNextCalendarYear7App(application, oesProperties) &&
      application?.isInArea === false &&
      !application?.eoiID &&
      (!application?.enrolmentType || application?.enrolmentType === 'CORE_OOA')
    )
  },
  isValidApplicationToSaveSchoolEmailNotification(application, ernRecord) {
    // Validate required input parameters
    if (
      !application ||
      !ernRecord ||
      !ernRecord.previousSchool?.prevSchoolId ||
      !ernRecord.previousSchool?.prevSchoolLastDate ||
      !ernRecord.previousSchool?.prevRegistrationStatusCode
    ) {
      return false
    }

    // Define valid registration statuses
    const ERN_REGISTRATION_STATUS = ['ENRCON', 'ENRLEA', 'ENRSUS']

    // Check if the registration status is valid
    const isValidRegStatus = ERN_REGISTRATION_STATUS.includes(
      ernRecord.previousSchool.prevRegistrationStatusCode
    )

    // Validate the last school date
    const validCurrentYr = this.validatePrevSchoolLastDate(
      ernRecord.previousSchool.prevSchoolLastDate
    )

    // Validate medical conditions allergies or nonAllergies: should exist and have at least one item
    const hasMedicalCondition =
      (Array.isArray(application.allergies) &&
        application.allergies.length > 0) ||
      (Array.isArray(application.nonAllergies) &&
        application.nonAllergies.length > 0)

    return isValidRegStatus && validCurrentYr && hasMedicalCondition
  },
  validatePrevSchoolLastDate(prevSchoolLastDate) {
    // Regular expression to validate MM/YYYY format
    const dateRegex = /^(0[1-9]|1[0-2])\/(\d{4})$/

    // Check if the input matches the required format
    if (!dateRegex.test(prevSchoolLastDate)) {
      return false // Invalid format
    }

    // Extract month and year from the input
    const [, , year] = prevSchoolLastDate.match(dateRegex)

    // Get the current year
    const currentYear = new Date().getFullYear()

    // Validate the year is the current year or higher than the current year
    return parseInt(year, 10) >= currentYear
  },
  getSchoolCode(value) {
    // prevSchoolId identifies the previous AU school. The id is actually
    // compound, e.g. "616|Coogee Public School"
    // This means we can use this id to get the name...
    try {
      return value.split('|')[0] || ''
    } catch (e) {
      return value || ''
    }
  },
  getSchoolName(value) {
    // prevSchoolId identifies the previous AU school. The id is actually
    // compound, e.g. "616|Coogee Public School"
    // This means we can use this id to get the name...
    try {
      return value.split('|')[1] || ''
    } catch (e) {
      return value || ''
    }
  },
  isParentContactDetailsResolved(application, parent) {
    const parentCarerIndex = application.parentCarers.findIndex(
      (item) => item.parentCarerRecordNo === parent.parentCarerRecordNo
    )
    var hasParentCarerContactDetails = false
    if (application.alertResolutions) {
      hasParentCarerContactDetails = application.alertResolutions.some(
        (item) => {
          const regex = new RegExp(
            `^(?!UNMATCHED_ERN_RECORD-)family>parentCarers\\[${parentCarerIndex}\\]\\.contactDetails`
          )
          return regex.test(item.id)
        }
      )
    }
    return hasParentCarerContactDetails
  },
  isOtherParentContactDetailsResolved(application, parent) {
    const parentCarerIndex = application.parentCarers.findIndex(
      (item) => item.parentCarerRecordNo === parent.parentCarerRecordNo
    )
    var hasParentCarerContactDetails = false
    if (application.alertResolutions) {
      hasParentCarerContactDetails = application.alertResolutions.some(
        (item) => {
          const regex = new RegExp(
            `^(?!UNMATCHED_ERN_RECORD-)others>parentCarers\\[${parentCarerIndex}\\]\\.contactDetails`
          )
          return regex.test(item.id)
        }
      )
    }
    return hasParentCarerContactDetails
  },
  isEmergencyContactDetailsResolved(application, contact) {
    //match the contact object with an application.emergencyContact array and get the index
    const recordIndex = application.emergencyContact.findIndex(
      (item) => item.contactRecordNo === contact.contactRecordNo
    )
    var hasEmergencyContactDetails = false
    if (application.alertResolutions) {
      hasEmergencyContactDetails = application.alertResolutions.some((item) => {
        const regex = new RegExp(
          `^(?!UNMATCHED_ERN_RECORD-)emergency>emergencyContact\\[${recordIndex}\\]\\.contactDetails`
        )
        return regex.test(item.id)
      })
    }
    return hasEmergencyContactDetails
  },
  filterPriorityLocalSchool(localSchoolArr = []) {
    if (!Array.isArray(localSchoolArr) || localSchoolArr.length === 0) {
      return []
    }
    // Return the original array if there's only one school
    if (localSchoolArr.length === 1) {
      return localSchoolArr
    }

    const priorityLocalSchools = localSchoolArr.filter(
      (localSchool) => localSchool?.priority === 'Y'
    )
    // If there are no priority schools, remove it from the local schools when there are more than one school
    if (priorityLocalSchools.length === 0) {
      return localSchoolArr.filter(
        (localSchool) => localSchool?.priority !== 'N'
      )
    }

    // Return the priority schools if any and the there are more than one school
    return priorityLocalSchools
  },
  getSchoolCatchmentQuery(long, lat) {
    return `
    SELECT s.school_code, c.priority, s.school_name, s.school_email, s.street, s.town_suburb, s.school_subtype, 
           s.postcode, s.longitude, s.latitude, s.gender, phone, website, c.calendar_year, 
           (EXTRACT(YEAR FROM now()) + 1) as next_year, 
           ARRAY_AGG(DISTINCT all_catch.calendar_year) as all_calendar_years
    FROM dec_schools_2020 as s
    JOIN catchments_2020 as c
    USING (school_code) 
    JOIN catchments_2020 AS all_catch 
    USING (school_code, catchment_level) 
    WHERE c.catchment_level = 'secondary' 
    AND ST_CONTAINS(c.the_geom, ST_SetSRID(ST_Point(${long}, ${lat}), 4326)) 
    GROUP BY s.school_code, s.school_name, s.school_email, s.street, s.town_suburb, s.postcode, 
             s.longitude, s.latitude, phone, website, s.school_subtype, c.calendar_year, s.gender, c.priority;
  `
  }
}
