<template>
  <v-app-bar
    app
    class="AppHeader white--text"
    :class="{ 'AppHeader--training': isTraining }"
    color="primary"
    :aria-hidden="showSettingsModal && showSettings"
  >
    <div class="logoTruncate">
      <img alt="NSW Government" src="@/assets/logo.svg" />
    </div>
    <h2 class="v-toolbar__title">
      <a
        @click.prevent="handleNavTitleClick"
        class="white--text text-decoration-none"
        >Online Enrolment System</a
      >
    </h2>
    <ChipDropdown
      v-if="isTraining"
      :menu-items="trainingMenuItems"
      sub-header="Training Options"
      chip-class="training-dropdown"
      :disabled="!mySelectedSchool"
      data-testid="chip-dropdown"
    >
      <template #title>Training - SI</template>
    </ChipDropdown>
    <div class="flex-grow-1"></div>

    <AppSchoolSwitcher
      :schools="mySchools"
      :selected-school="mySelectedSchool"
      @onChangeSchool="switchSchool"
      v-if="mySchools.length"
      class="mr-4"
    />
    <div class="controls">
      <AppSettingsModal
        v-if="showSettingsModal"
        v-model="showSettings"
        :school="mySelectedSchool"
        @opened="settingsOpened"
        data-testid="app-settings-modal"
      />
    </div>
    <div class="controls">
      <AppHelpMenu />
    </div>
    <div class="controls">
      <AppProfileMenu />
    </div>
  </v-app-bar>
</template>

<script>
import fieldHelperMixin from '@/helpers/fieldHelperMixin'
import AppSchoolSwitcher from '@/components/app/AppSchoolSwitcher'
import AppHelpMenu from '@/components/app/AppHelpMenu'
import AppProfileMenu from '@/components/app/AppProfileMenu'
const ChipDropdown = () => import('@/components/app/ChipDropdown') //We only want the dropdown when in trainingMode, so we dynamically import the component
import { REF_DATA, STREAM } from '@/constants'
import { mapGetters } from 'vuex'

export default {
  name: 'AppHeader',
  mixins: [fieldHelperMixin],
  components: {
    AppSchoolSwitcher,
    AppHelpMenu,
    AppProfileMenu,
    ChipDropdown
  },
  computed: {
    ...mapGetters({
      currentStream: 'currentStream'
    }),
    showSettings: {
      get: function () {
        return this.$store.state.showSettings
      },
      set: function (value) {
        this.$store.commit('set', ['showSettings', value])
        if (!value) {
          setTimeout(() => {
            this.$store.commit('set', ['settingsTab', 0])
          }, 500)
        }
      }
    },
    mySchools() {
      let schools = this.$store.state.schools
      return schools.sort((a, b) => {
        var textA = a.schoolName.toUpperCase()
        var textB = b.schoolName.toUpperCase()
        return textA < textB ? -1 : textA > textB ? 1 : 0
      })
    },
    mySelectedSchool() {
      return this.$store.state.selectedSchool
    },
    isTraining() {
      return this.$store.state.inTrainingMode
    },
    schoolCode() {
      return this.mySelectedSchool ? this.mySelectedSchool.schoolCode : null
    },
    catchmentLevel() {
      if (this.mySelectedSchool) {
        return this.mySelectedSchool.catchmentLevel === 'central'
          ? 'primary'
          : this.mySelectedSchool.catchmentLevel
      }
      return null
    },
    trainingMenuItems() {
      const items = []
      if (this.mySelectedSchool) {
        items.push({
          text: 'Training - Local area enrolment (Parent Interface)',
          externalLink: `${process.env.VUE_APP_PI_BASE_URL}/schoolenrolment/?schoolCode=${this.schoolCode}&catchmentLevel=${this.catchmentLevel}`,
          disabled: !this.mySelectedSchool
        })

        if (this.mySelectedSchool.ooa) {
          items.push({
            text: 'Training - Out of area enrolment (Parent Interface)',
            externalLink: `${process.env.VUE_APP_OOA_PI_BASE_URL}/ooainfo?schoolCode=${this.schoolCode}&catchmentLevel=${this.catchmentLevel}`
          })
        }

        if (
          (this.mySelectedSchool.y67t &&
            this.mySelectedSchool.catchmentLevel === 'primary') ||
          (this.mySelectedSchool.y67t &&
            this.currentStream === STREAM.Y67T_PRIMARY)
        ) {
          items.push({
            text: 'Training - Moving to high school (Parent Interface)',
            externalLink: `${process.env.VUE_APP_Y67T_PI_BASE_URL}/?schoolCode=${this.schoolCode}`
          })
        }
      }

      items.push({
        text: 'Training - SI (School Interface)',
        selected: true,
        disabled: true
      })

      return items
    },
    showSettingsModal() {
      let settingsEnabledSchools = this.$store.state.settingsEnabledSchools
      let settingsEnabled = false
      if (settingsEnabledSchools != null && settingsEnabledSchools.length > 0) {
        settingsEnabled = settingsEnabledSchools.includes(
          parseInt(this.schoolCode)
        )
      }
      return this.mySelectedSchool != null || settingsEnabled
    }
  },
  methods: {
    switchSchool(school) {
      if (this.$store.state.referenceData) {
        // Clear scholastic years when changing schools as they are school
        // specific. They will reload with getApplication if empty.
        this.$store.dispatch('setReferenceData', [
          REF_DATA.SCHOLASTIC_YEAR,
          null
        ])
      }

      this.$store.commit('set', ['selectedSchool', school])

      localStorage.setItem(
        'selectedSchool',
        JSON.stringify({ ...school, uid: this.$store.state.userID })
      )

      // Ensures that we always return to the application list when
      // changing school. This will also fire the mounted() event on
      // ApplicationListView.vue, which refreshes the list.
      let currentRoute = this.$router.currentRoute.path
      if (currentRoute !== '/') this.$router.push('/')
    },
    settingsOpened(opened) {
      this.$emit('fullScreenModalOpened', opened)
    },
    handleNavTitleClick() {
      this.$store.commit('set', ['currentStream', 'dashboard'])
    }
  }
}
</script>

<style scoped lang="scss">
.AppHeader {
  ::v-deep button.v-btn.circular-btn {
    border: none;
    width: 2.8rem;
    height: 2.8rem;
    &:focus {
      box-shadow: 0 0 0 2px $color-focus;
    }
  }

  padding: 0 0 0 1rem;

  &.AppHeader--training {
    background-color: $color-training !important;
    border-color: $color-training !important;
  }

  &__pi-link {
    color: $color-training;
    background-color: #fff;
    margin-left: 2rem;
    padding: 0 1rem;
    height: 2.5rem;
    border-radius: 1.5rem;
  }

  .logoTruncate {
    width: 35px;
    overflow: hidden;
    margin-right: 1em;
  }

  h1 {
    font-weight: normal;
  }

  h2.v-toolbar__title {
    margin-bottom: 0;
  }

  .userIcon {
    margin-right: 0.5em;
    opacity: 0.5;
  }
}

::v-deep .training-dropdown {
  margin-left: 1rem;
}

.help {
  line-height: 1;
  font-size: 1.5rem;
  vertical-align: middle;
  display: inline-block;
  .icon {
    border: 1px solid;
    margin: 0;
  }
}

::v-deep .v-toolbar__content {
  width: 100%;
}

.settingsIcon {
  border: none;
}
@media only screen and (max-width: 800px) {
  .AppHeader {
    width: 650px;
  }
}
.v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: none;
}
</style>
