import UTILS from '@/store/utils'
import {
  FIELD_TYPE,
  RESOLUTION_TYPE,
  CONFLICT_ALERTING,
  SUPPORTING_DOCS,
  STREAM
} from '@/constants'
import FIELD from '@/helpers/fieldHelper'
import CONFLICT from '@/helpers/conflictHelper'
import SECTIONS_LA from '@/applicationDefinition/applicationSections'
import _cloneDeep from 'lodash/cloneDeep'

const ERN_PREFIX = 'UNMATCHED_ERN_RECORD-'
const NEW_RECORD = -1 // When resolving record linking, any record marked as 'new' by the user will be assigned this id as the linked value. It indicates that there is no linked ERN record, and that this linking is now resolved.

/*
  The application model prepares all data required by the UI in order to display and
  edit an application.

  It is necessary because the application data structure has complex record nesting, and
  these records must support conflict resolution. Conflict resolution at a record-level
  is extremely complex, requiring record matching algorithms, the management of record links
  between OES and ERN records, and the ability to rewrite records and children based on
  resolution options selected by the admin. All of this requires complex evaluation at
  every level of the record hierarchy. So we do all of this evaluation in a single place
  (the application model) and only once (whenever an application value changes). Not only
  is this efficient but it also gives visibility to our business logic (as the model can
  be inspected at any time by accessing window.MODEL).
*/

export default {
  create(oesApplication, ernApplication, store) {
    /*
      Creates an object model for the student application in:

      * window.MODEL

      MODEL uses a global variable because it contains too much data/metadata/business logic to work
      efficiently inside Vuex state. Instead MODEL is made universally accessible (in a nice way) via

      * store.getters.model

      In order to refresh the UI after the model has been recalculated we must:

      * store.dispatch('renderModel')

      To create the model we take the field definitions and apply them to the OES and ERN application
      data. The result is a flat-list of field model rows which map 1:1 to the fields on screen. This
      model is then used to underpin all aspects of application form rendering, alerting and conflict
      resolution.

      MODEL ROW PARAMETERS (only included if not null):

      * id                   <string>  Unique row id, e.g. "family>parentCarers[0].contactDetails[1].contactValue". Note that alert resolutions (stored in the OES application) reference these ids, so the format should not be changed.
      * apiKey               <string>  Maps to an OES application api datapoint. Same as "id" (above) without the section prefix (e.g."family>"). Also the same as "field.apiKey" except for RECORD model rows, whose "field.apiKey" will instead point to the parent array.
      * section              <object>  Section that the row belongs to
      * field                <object>  Field definition that the model row relates to. Note that model rows of type RECORD do not have a field definition, so we assign their COLLECTION field definition instead.
      * parent               <object>  Parent model row (if one exists)
      * label                <string>  User text label
      * type                 <string>  Field type (taken from the field definition). Model rows representing collection records use a special "RECORD" type.
      * oesValue             <any>     OES field/record value. If field is configured with a default value, this will be applied if value is empty.
      * oesDisplayValue      <string>  OES value displayed to the user. This could be the same as oesValue, or it could be a looked-up or specially formatted value (for drop-lists, date fields etc)
      * ernValue             <any>     ERN field/record value, if available. Note that this could be from a linked record. If field is configured with a default value, this will be applied if value is empty.
      * ernDisplayValue      <string>  ERN value displayed to the user, if available. This could be the same as ernValue, or it could be a looked-up or specially formatted value (for drop-lists, date fields etc)
      * conflictSettings     <object>  If field has a conflict, these options can configure how the conflict is displayed and resolved.
      * validationMessage    <string>  If field has failed validation, this is the message for the user.
      * warningMessage       <string>  If field has an ignorable warning, this is the warning message for the user.
      * depth                <integer> Indicates the level of record nesting. 0 is root level.
      * addLabel             <string>  For COLLECTION model rows, contains the user text for the "add record" button.
      * resolution           <object>  If row is resolved, this is the resolution object.
      * recordIndex          <integer> For RECORD model rows, contains the index of the original OES record.
      * summaryText          <string>  For RECORD model rows, contains a short text summary of the record if the COLLECTION field has been defined with a summaryText() function.
      * matchedRecords       <array>   For COLLECTION model rows, contains a JSON array of OES-ERN record links. Each link contains oesRecord, oesIndex, ernRecord and ernIndex.
      * unmatchedOesRecords  <array>   For COLLECTION model rows, contains a JSON array of unmatched OES records (if any).
      * unmatchedErnRecords  <array>   For COLLECTION model rows, contains a JSON array of unmatched ERN records (if any).

      BOOLEAN FLAGS (only included if true)

      * isMissing                   - True if field is required and no value is present
      * isInvalid                   - True if field has failed validation
      * isWarning                   - True if field has an ignorable warning message and has not been ignored
      * isWarningIgnored            - True if warning message has been ignored
      * isHidden                    - True if field is hidden
      * isReadOnly                  - True if field/record/collection is read only
      * isMatchedRecord             - True if record is matched to an ERN record
      * isNewRecord                 - True if record has been resolved as "New record"
      * isUnresolvedMatching        - True if record linking needs to be resolved by the user
      * isUnmatchedErnRecord        - True if record is an ERN record which has no match in OES, and has automatically been brought into the model so that the ERN record can be displayed grayed out in the UI. Any child records will not have this flag set.
      * isInsideUnmatchedErnRecord  - True if field/record is a descendent of an unmatched ERN record, or is the unmatched record itself.
      * isConflict                  - True if an unresolved conflict is present
      * isResolved                  - True if field has been resolved OR is part of a resolved group/record/collection (note that the resolution object is only present on the resolved model row and is not added to any children)
      * isResolutionObsolete        - True if ERN value has changed since resolving the conflict

    */
    if (!oesApplication) {
      return
    }

    FIELD.setStore(store)
    const { getters, dispatch } = store
    const isSentToErn = getters.isSentToErn
    const isWithdrawn = getters.isWithdrawn
    const isArchived = getters.currentStream === STREAM.ARCHIVE
    const isStatusInvalid = getters.isInvalid
    const isInternalTransfer = getters.isInternalTransfer

    let dummyErnRecordIndex = 99 // Used to prevent model row id clash when unmatched ERN records are present
    let model = []
    const applicationSections = {
      LA: SECTIONS_LA
    }
    let sections = applicationSections[store.state.applicationType] || []

    // Cloning is necessary because OES and ERN application data
    // is reactive (in Vuex state), and we do not want or need it
    // to be reactive inside our model.
    oesApplication = UTILS.clone(oesApplication)
    ernApplication = UTILS.clone(ernApplication)

    //API is serving an empty object for supporting documents if no docments have been uploaded or only the categories with files, we have to hard code the categories at the front end.
    let supportingDocsCategories = _cloneDeep(SUPPORTING_DOCS)
    supportingDocsCategories.map((doc) => {
      if (
        oesApplication.supportingDocuments &&
        !oesApplication.supportingDocuments[doc.category]
      )
        oesApplication.supportingDocuments[doc.category] = []
    })
    // This is to conbime three fields in one.
    if (
      oesApplication.sreSeeAmaSelectionLabel &&
      oesApplication.sreSeeAmaSelectionName &&
      oesApplication.sreSeeAmaSelectionProviders
    ) {
      const sreSeeAmaSelectionLabel = `${oesApplication.sreSeeAmaSelectionLabel}: `
      const sreSeeAmaSelectionName = oesApplication.sreSeeAmaSelectionName
      const sreSeeAmaSelectionProviders = ` (${oesApplication.sreSeeAmaSelectionProviders.replaceAll(
        '|',
        ','
      )})`
      oesApplication.sreSeeAmaSelection =
        oesApplication.sreSeeAmaSelectionLabel === 'No option'
          ? 'No option has been specified. Please follow up with the parent/carer.'
          : `${sreSeeAmaSelectionLabel}${sreSeeAmaSelectionName}${sreSeeAmaSelectionProviders}`
    }
    sections.forEach((section) => {
      section.fields.forEach((field) => {
        let oesValue = getFieldValue(field)
        const ernValue = getFieldValue(field, true)

        if (calculateErnCoalesceOperation(field, oesValue, ernValue)) {
          setApplicationValue(field.apiKey, ernValue)
          oesValue = ernValue
        }
        addFieldToModel({
          section: { id: section.id, label: section.label },
          field,
          oesValue,
          ernValue
        })
      })
    })

    window.MODEL = model
    dispatch('set', ['application', UTILS.clone(oesApplication)]) // Clone to prevent Vuex tracking our model application data
    dispatch('renderModel') // Lets Vue know the model has changed so the UI can re-render
    return

    // HOISTED LOCAL HELPER FUNCTIONS -------------------------------------------------------

    // NOTE: Any model row property set to "undefined" will be completely deleted
    // before adding the row to the model. We want to keep the model as uncluttered
    // and humanly-readable as possible.

    function addFieldToModel({
      section,
      field,
      oesValue,
      ernValue,
      parent, // Parent model row
      isUnmatchedErnRecord
    }) {
      let modelRow = {
        id: FIELD.getFieldId(section, field) || undefined,
        section: section,
        type: field.type || 'TEXT',
        field: field,
        parent: parent,
        depth: parent ? parent.depth + 1 : 0,
        isUnmatchedErnRecord: isUnmatchedErnRecord,
        isInsideUnmatchedErnRecord:
          isUnmatchedErnRecord || (parent && parent.isInsideUnmatchedErnRecord)
      }

      if (modelRow.isInsideUnmatchedErnRecord) {
        // To display unmatched ERN records in the UI we need to add them into the data model,
        // flag them as isUnmatchedErnRecord = true, and move the ERN value into the OES value
        // (so that the UI does not have to be rewritten to switch between OES & ERN values).
        modelRow.id = modelRow.id ? ERN_PREFIX + modelRow.id : undefined
        modelRow.oesValue = ernValue !== null ? ernValue : oesValue
        modelRow.ernValue = undefined
        addIsHiddenToModelRow(modelRow)
      } else {
        modelRow.apiKey = field.apiKey
        modelRow.oesValue = oesValue
        modelRow.ernValue = ernValue
        addIsHiddenToModelRow(modelRow)
        if (
          !modelRow.isHidden &&
          !isSentToErn &&
          !isArchived &&
          !isStatusInvalid &&
          !isInternalTransfer &&
          !isWithdrawn
        ) {
          addIsMissingToModelRow(modelRow)
          addIsInvalidToModelRow(modelRow)
          addIsWarningToModelRow(modelRow)
          addResolutionToModelRow(modelRow)
          CONFLICT.setConflictFlag(modelRow)
        }
      }
      addIsReadOnlyToModelRow(modelRow)
      addDisplayValueToModelRow(modelRow)
      addLabelToModelRow(modelRow)
      model.push(UTILS.removeKeys(modelRow, undefined)) // Remove null keys to make model cleaner and easier to read
      addSpecialFieldInfoToModel(modelRow)
      return modelRow
    }

    function addSpecialFieldInfoToModel(modelRow) {
      // Some special field types need extra information adding into the data model...
      let { section, field, ernValue, resolution } = modelRow

      if (field.type === FIELD_TYPE.COLLECTION) {
        // Links matching OES-ERN records, then adds OES records and fields into data model
        if (
          !modelRow.isInsideUnmatchedErnRecord &&
          !isSentToErn &&
          !isArchived &&
          !isWithdrawn
        ) {
          matchRecordsWithErn(modelRow)
        }
        addCollectionRecordsToModel(modelRow)
        filterOutCollectionRecords(modelRow)
      } else if (field.type === FIELD_TYPE.GROUP) {
        // Add group fields into model...
        FIELD.getGroupFields(field, null, oesApplication).forEach(
          (recordField) => {
            addFieldToModel({
              section: section,
              field: recordField,
              parent: modelRow,
              oesValue: getFieldValue(recordField),
              ernValue: getFieldValue(recordField, true)
            })
          }
        )
      } else if (field.type === FIELD_TYPE.ADDRESS) {
        // Link ernAddressRecordNo if ERN record present...
        if (ernValue && ernValue.ernAddressRecordNo) {
          let linkRecordNumber = ernValue.ernAddressRecordNo
          if (resolution && resolution.type === RESOLUTION_TYPE.AB_OES) {
            // Addresses resolved with the OES value should have their ERN record link cleared
            linkRecordNumber = null
          }
          setApplicationValue(
            `${field.apiKey}.ernAddressRecordNo`,
            linkRecordNumber
          )
        }
      }
    }

    function filterOutCollectionRecords(modelRow) {
      // Some collections are defined with a filter (e.g. isEnrolmentOwner). If so, these records
      // are filtered out. However they are not filtered out until all child records and fields
      // have first been added into the model. This is because child model rows must reference
      // the original record indexes, and filtering out records would change those indexes.
      let filter = modelRow.field.filter
      if (filter) {
        modelRow.oesValue = modelRow.oesValue.filter(
          (record) => record[filter.apiKey] === filter.value
        )
        if (modelRow.ernValue) {
          modelRow.ernValue = modelRow.ernValue.filter(
            (record) => record[filter.apiKey] === filter.value
          )
        }
      }
    }

    function calculateErnCoalesceOperation(field, oesValue, ernValue) {
      return (
        Boolean(field.conflictSettings?.allowErnCoalesce) &&
        UTILS.isEmpty(oesValue) &&
        !UTILS.isEmpty(ernValue)
      )
    }

    function addCollectionRecordsToModel(modelRow) {
      // Add records and their fields into the model...
      if (modelRow.oesValue) {
        modelRow.oesValue.forEach((record, recordIndex) => {
          addRecordAndFieldsToModel(
            modelRow,
            record,
            recordIndex,
            modelRow.isUnmatchedErnRecord
          )
        })
      }

      // Unmatched ERN records are added into the model so that they
      // can be displayed greyed out in the UI
      if (modelRow.unmatchedErnRecords) {
        modelRow.unmatchedErnRecords.forEach((record) => {
          /*
            modelRow ids reflect the OES data structure, e.g.:
              family>parentCarers[0].contactDetails[1].contactType

            When an unmatched ERN record is added into the data model
            we must use a dummy record index to ensure the id remains
            unique. A simple prefix is not enough, although the reasons
            are simply too complex to explain briefly.
          */
          dummyErnRecordIndex++
          addRecordAndFieldsToModel(modelRow, record, dummyErnRecordIndex, true)
        })
      }

      function addRecordAndFieldsToModel(
        modelRow,
        record,
        recordIndex,
        isUnmatchedErnRecord
      ) {
        // Each collection record is added as a 'RECORD' type row in the model
        if (FIELD.isRecordFilteredOut(modelRow.field, record)) {
          return
        }
        let isInsideUnmatchedErnRecord =
          isUnmatchedErnRecord || modelRow.isInsideUnmatchedErnRecord
            ? true
            : undefined
        let recordId =
          (isInsideUnmatchedErnRecord ? ERN_PREFIX : '') +
          FIELD.getFieldId(modelRow.section, modelRow.field) +
          `[${recordIndex}]`
        let isNewRecord =
          modelRow.field.ernRecordNumberField &&
          record[modelRow.field.ernRecordNumberField] === NEW_RECORD
        let recordLink = undefined
        if (!isInsideUnmatchedErnRecord && modelRow.matchedRecords) {
          recordLink = modelRow.matchedRecords.find(
            (link) => link.oesIndex === recordIndex
          )
        }

        let recordModelRow = {
          id: recordId,
          section: modelRow.section,
          type: FIELD_TYPE.RECORD,
          field: modelRow.field,
          label:
            typeof modelRow.field.heading === 'function'
              ? modelRow.field.heading(record)
              : '',
          parent: modelRow,
          oesValue: record,
          ernValue: recordLink ? recordLink.ernRecord : undefined,
          isMatchedRecord: recordLink ? true : undefined,
          isNewRecord: isNewRecord || undefined,
          recordIndex: recordIndex,
          depth: modelRow.depth
        }
        if (isInsideUnmatchedErnRecord) {
          recordModelRow.isUnmatchedErnRecord = isUnmatchedErnRecord
          recordModelRow.isInsideUnmatchedErnRecord = isInsideUnmatchedErnRecord
          if (
            recordModelRow.field.conflictAlerting ===
            CONFLICT_ALERTING.AB_GROUP_CONTACT
          ) {
            addResolutionToModelRow(recordModelRow)
            if (!recordModelRow.isResolved) {
              addIsUnresolvedMatchingToModelRow(recordModelRow)
            }
          }
        } else {
          recordModelRow.apiKey = `${modelRow.field.apiKey}[${recordIndex}]`
          if (
            recordModelRow.field.conflictAlerting !==
            CONFLICT_ALERTING.AB_GROUP_CONTACT
          ) {
            addIsUnresolvedMatchingToModelRow(recordModelRow)
          }
          addResolutionToModelRow(recordModelRow)
        }
        addSummaryTextToModelRow(recordModelRow, record)
        addIsReadOnlyToModelRow(recordModelRow)
        model.push(UTILS.removeKeys(recordModelRow, undefined))

        // All the fields belonging to the record are added as subsequent rows in the model
        addRecordFieldsToModel(recordModelRow)
      }
    }

    function addRecordFieldsToModel(modelRow) {
      let fields = FIELD.getRecordFields(
        modelRow.field,
        modelRow.oesValue,
        modelRow.recordIndex,
        oesApplication
      )
      fields.forEach((recordField) => {
        addFieldToModel({
          section: modelRow.section,
          field: recordField,
          parent: modelRow,
          oesValue: getFieldValue(recordField, false, modelRow.oesValue),
          ernValue: getFieldValue(recordField, true, modelRow.ernValue || null)
        })
      })
    }

    function matchRecordsWithErn(modelRow) {
      // Updates an OES collection by setting links to ERN record matches.

      if (!modelRow.field.ernMatch) {
        return // No matching without defined matching criteria
      }
      let oesRecords = modelRow.oesValue || []
      let ernRecords = modelRow.ernValue || []
      let linkField = modelRow.field.ernRecordNumberField

      // Once AB & AB_GROUP collections are matched, they are not rematched (unless
      // the user manually changes the matching). However LIST-type collections are
      // always rematched so that matching can change based on field changes.
      let isAllowRematch =
        modelRow.field.conflictAlerting === CONFLICT_ALERTING.LIST
      modelRow.matchedRecords = []

      // Compares every OES record with every ERN record and links any matches...
      // As a fix to DSE-2712 the previous forEach was split into the following two to remove potential racing situations.
      oesRecords.forEach((oesRecord, oesIndex) => {
        addToMatchedRecordsIfAlreadyLinked(oesRecord, oesIndex)
      })

      oesRecords.forEach((oesRecord, oesIndex) => {
        matchRecordIfNecessary(oesRecord, oesIndex)
      })

      setUnmatchedRecords()

      // Helper functions...

      function isOesRecordLinked(oesIndex) {
        let link = modelRow.matchedRecords.find(
          (link) => link.oesIndex === oesIndex
        )
        return link ? true : false
      }

      function isErnRecordLinked(ernIndex) {
        let link = modelRow.matchedRecords.find(
          (link) => link.ernIndex === ernIndex
        )
        return link ? true : false
      }

      function addToMatchedRecordsIfAlreadyLinked(oesRecord, oesIndex) {
        // If OES record has already been linked, add it to our list of linked ERN ids.
        // Note that a link value of -1 indicates that the user has resolved the record
        // as "New record".
        let linkValue = oesRecord[linkField]
        if (
          linkValue > 0 &&
          !isAllowRematch &&
          !FIELD.isRecordFilteredOut(modelRow.field, oesRecord)
        ) {
          let ernIndex = ernRecords.findIndex(
            (ernRecord) => ernRecord[linkField] === linkValue
          )

          modelRow.matchedRecords.push({
            oesIndex: oesIndex,
            ernIndex: ernIndex,
            oesRecord: oesRecord,
            ernRecord: ernRecords[ernIndex]
          })
        }
      }

      function matchRecordIfNecessary(oesRecord, oesIndex) {
        // If OES record is unlinked or is allowed to be rematched, look for an ERN match...
        if (
          (!oesRecord[linkField] || isAllowRematch) &&
          !FIELD.isRecordFilteredOut(modelRow.field, oesRecord)
        ) {
          var ernIndex = ernRecords.findIndex((ernRecord, ernIndex) => {
            return (
              modelRow.field.ernMatch(oesRecord, ernRecord) &&
              !isErnRecordLinked(ernIndex)
            )
          })
          if (ernIndex >= 0) {
            if (linkField) {
              oesRecord[linkField] = ernRecords[ernIndex][linkField]
            }
            modelRow.matchedRecords.push({
              oesIndex: oesIndex,
              ernIndex: ernIndex,
              oesRecord: oesRecord,
              ernRecord: ernRecords[ernIndex]
            })
          }
        }
      }

      function setUnmatchedRecords() {
        // Adds unmatched OES and ERN records into the COLLECTION model row
        let unmatchedOesRecords = []
        let unmatchedErnRecords = []
        oesRecords.forEach((oesRecord, oesIndex) => {
          if (
            !FIELD.isRecordFilteredOut(modelRow.field, oesRecord) &&
            !isOesRecordLinked(oesIndex)
          ) {
            unmatchedOesRecords.push(oesRecord)
          }
        })
        ernRecords.forEach((ernRecord, ernIndex) => {
          if (
            !FIELD.isRecordFilteredOut(modelRow.field, ernRecord) &&
            !isErnRecordLinked(ernIndex)
          ) {
            unmatchedErnRecords.push(ernRecord)
          }
        })
        if (unmatchedOesRecords.length) {
          modelRow.unmatchedOesRecords = unmatchedOesRecords
        }
        if (unmatchedErnRecords.length) {
          modelRow.unmatchedErnRecords = unmatchedErnRecords
        }
      }
    }

    function setApplicationValue(apiKey, value) {
      // eval is used for setting an application value because apiKey
      // might point to a nested value, e.g.
      //
      if (value !== undefined) {
        try {
          eval(`oesApplication.${apiKey} = value`)
        } catch (e) {
          UTILS.log(`COULD NOT SET APPLICATION VALUE: ${apiKey} = ${value}`)
        }
      }
    }

    function addResolutionToModelRow(modelRow) {
      if (
        isSentToErn ||
        isArchived ||
        isWithdrawn ||
        isStatusInvalid ||
        isInternalTransfer
      ) {
        return
      }

      let resolution = oesApplication.alertResolutions.find(
        (resolution) =>
          resolution.id === modelRow.id &&
          resolution.type !== RESOLUTION_TYPE.IGNORE
      )
      if (resolution) {
        modelRow.isResolved = true
        modelRow.resolution = resolution
        if (
          !UTILS.isMatch(modelRow.ernValue, resolution.originalErnValue) &&
          modelRow.field.conflictAlerting !== CONFLICT_ALERTING.AB_GROUP_CONTACT
        ) {
          // If ERN value has changed since resolving, flag it...
          modelRow.isResolutionObsolete = true
        }
      }

      if (modelRow.parent && modelRow.parent.isResolved) {
        // When a record/group/collection is resolved, all child
        // fields are flagged as resolved. However only the original
        // resolved parent will have the resolution object.
        modelRow.isResolved = true
      }
    }

    function addIsUnresolvedMatchingToModelRow(recordModelRow) {
      // Adds isUnresolvedMatching flag into model row. Unresolved matching
      // is where an OES record has not been matched while unmatched ERN records
      // are present. Therefore we need to flag the record and ask the user to
      // resolve it.
      let collectionField = recordModelRow.field
      let linkedRecordNumber =
        recordModelRow.oesValue[collectionField.ernRecordNumberField]
      if (
        collectionField.conflictAlerting !== CONFLICT_ALERTING.LIST &&
        !recordModelRow.ernValue &&
        recordModelRow.parent.unmatchedErnRecords &&
        linkedRecordNumber !== NEW_RECORD
      ) {
        recordModelRow.isUnresolvedMatching = true
      }
    }

    function addSummaryTextToModelRow(modelRow, record) {
      try {
        let summaryText =
          typeof modelRow.field.summaryText === 'function'
            ? modelRow.field.summaryText(record)
            : undefined
        if (summaryText) {
          modelRow.summaryText = summaryText
        }
      } catch (e) {
        UTILS.log(`${modelRow.id} - COULD NOT GET SUMMARY TEXT`)
      }
    }

    function addDisplayValueToModelRow(modelRow) {
      // Display values are what gets displayed to the user. In many cases they
      // are the same as the api value, but in some cases the value needs
      // to be formatted (e.g. dates) or looked up from reference data (e.g. droplist
      // values).
      if (
        modelRow.type !== FIELD_TYPE.COLLECTION &&
        modelRow.type !== FIELD_TYPE.GROUP
      ) {
        modelRow.oesDisplayValue = FIELD.getDisplayValue(
          modelRow.field,
          modelRow.oesValue
        )
        if (modelRow.ernValue !== undefined) {
          modelRow.ernDisplayValue = FIELD.getDisplayValue(
            modelRow.field,
            modelRow.ernValue
          )
        }
      }
    }

    function addIsWarningToModelRow(modelRow) {
      // Sets isWarning, isWarningIgnored and warningMessage values in model row.
      // Warnings are similar to field validation messages except that they offer
      // an "ignore" option to the user.
      if (!modelRow.field.alert || isArchived || isInternalTransfer) {
        return
      }
      try {
        let warningMessage = modelRow.field.alert(oesApplication)
        if (warningMessage) {
          modelRow.warningMessage = warningMessage
          modelRow.isWarningIgnored = oesApplication.alertResolutions.find(
            (resolution) =>
              resolution.id === modelRow.id &&
              resolution.type === RESOLUTION_TYPE.IGNORE
          )
            ? true
            : undefined
          modelRow.isWarning = !modelRow.isWarningIgnored
        }
      } catch (e) {
        // No warning message so nothing else to do here...
      }
    }

    function addLabelToModelRow(modelRow) {
      if (modelRow.field.placeholderTitle) {
        modelRow.label = modelRow.field.placeholderTitle
      } else if (modelRow.type === FIELD_TYPE.GROUP && modelRow.field.heading) {
        modelRow.label = modelRow.field.heading(oesApplication)
      } else if (!modelRow.field.heading) {
        modelRow.label = FIELD.getFieldLabel(
          modelRow.field,
          false,
          oesApplication
        )
      }
      // If field is a collection that allows the user to add new records,
      // set an "addLabel" property to contain the text for the "Add" option.
      if (modelRow.field.addLabel) {
        modelRow.addLabel = modelRow.field.addLabel
        if (modelRow.field.collectionLabel) {
          // for display on error pop up
          modelRow.label = modelRow.field.collectionLabel
        }
      }
    }

    function addIsReadOnlyToModelRow(modelRow) {
      let { field, parent } = modelRow
      try {
        if (
          modelRow.isInsideUnmatchedErnRecord ||
          isSentToErn ||
          isWithdrawn ||
          isStatusInvalid ||
          isInternalTransfer ||
          isArchived ||
          (parent && parent.isReadOnly) ||
          field.readOnly === true ||
          (typeof field.readOnly === 'function' &&
            field.readOnly(oesApplication))
        ) {
          modelRow.isReadOnly = true
        }
      } catch (e) {
        UTILS.log(`ERROR IN ${field.apiKey} readOnly() function!`)
      }
    }

    function addIsHiddenToModelRow(modelRow) {
      let { field, parent } = modelRow
      if (
        (parent && parent.isHidden) ||
        !FIELD.isFieldVisible(field, oesApplication)
      ) {
        modelRow.isHidden = true
      }
    }

    function addIsMissingToModelRow(modelRow) {
      if (isArchived || isInternalTransfer) {
        return
      }
      modelRow.isMissing = FIELD.isMissing(
        modelRow.field,
        modelRow.oesValue,
        oesApplication
      )
        ? true
        : undefined
    }

    function addIsInvalidToModelRow(modelRow) {
      if (isArchived || isInternalTransfer) {
        return
      }
      let validationMessage = FIELD.getValidationMessage(
        modelRow.field,
        modelRow.oesValue,
        oesApplication
      )
      modelRow.isInvalid = validationMessage ? true : undefined
      modelRow.validationMessage = validationMessage || undefined
    }

    function getFieldValue(field, isErn, record) {
      /*
        Gets a field value from the specified data.
        * field <json> - Field to get value for
        * isErn <boolean> - If true, gets ERN value
        * record <json> - If specified, gets value from this record instead of from the application data json
      */
      let value = null
      if (isErn && !ernApplication) {
        return // Return an undefined ERN field value if no linked SRN
      }
      if (
        field.type !== FIELD_TYPE.HEADING &&
        field.type !== FIELD_TYPE.LINEBREAK &&
        field.type !== FIELD_TYPE.CUSTOM
      ) {
        try {
          if (record === undefined) {
            value = eval(
              `${isErn ? 'ernApplication' : 'oesApplication'}.${field.apiKey}`
            )
          } else if (record !== null) {
            value = getValueFromRecord()
          }
        } catch (e) {
          if (!isErn) {
            // Generally we expect our OES UI fields to exist in the application json,
            // so we log any missing fields. The same expectation is not true of ERN
            // data, so no reason to log it.
            UTILS.log(`${field.apiKey} - OES FIELD VALUE NOT FOUND`)
          }
        }
        setDefaultValueIfEmpty()
      }
      return value

      function getValueFromRecord() {
        let relativeApiKey = FIELD.getRelativeApiKey(field.apiKey)
        return eval(`record.${relativeApiKey}`) // Note that relativeApiKey could point to a nested record value, hence the eval()
      }

      function setDefaultValueIfEmpty() {
        // If field is empty and has a default value set up, set that value...
        if (!isErn && field.default && !value) {
          let defaultValue
          if (typeof field.default === 'function') {
            defaultValue = field.default(oesApplication)
          } else {
            defaultValue = field.default
          }
          setApplicationValue(field.apiKey, defaultValue)
          value = defaultValue
        }
      }
    }
  }
}
