<template>
  <v-card class="section-card">
    <!-- Card Header -->
    <v-card-title>
      <v-avatar size="40" class="mr-4 section-avatar">
        <v-icon color="primary">{{ icon }}</v-icon>
      </v-avatar>
      <span class="section-card-title" data-testid="section-card-title">{{
        title
      }}</span>
    </v-card-title>
    <!-- Sections -->
    <template v-if="sections.length > 0">
      <v-card-text
        v-for="(section, sectionIndex) in sections"
        :key="sectionIndex"
        :data-testid="section.title"
      >
        <v-row class="section">
          <!-- Section Title and Description -->
          <v-col v-if="isDisplaySection(section)" class="mr-4">
            <h4
              v-if="section.title"
              class="font-weight-bold"
              data-testid="section-title"
            >
              {{ section.title }}
            </h4>
            <p
              v-if="section.description"
              class="mt-4"
              data-testid="section-description"
            >
              {{ section.description }}
            </p>
          </v-col>
          <!-- Fields -->
          <v-col
            v-if="section.fields && section.fields.length > 0"
            :class="{
              'ml-9': isDisplaySection(section),
              'ml-3':
                !isDisplaySection(section) && !isNoDescriptionTitle(section)
            }"
          >
            <div
              v-for="(field, fieldIndex) in section.fields"
              :key="fieldIndex"
              class="section-field"
            >
              <h5 class="field-label" data-testid="field-label">
                {{ field.label }}
              </h5>
              <p :class="!field.value ? 'empty-value' : ''">
                {{ field.value || '(empty)' }}
              </p>
            </div>
          </v-col>
        </v-row>
        <v-divider v-if="sectionIndex !== sections.length - 1" />
      </v-card-text>
    </template>
  </v-card>
</template>

<script>
/**
 * @typedef {Object} Field
 * @property {string} label - The label of the field.
 * @property {string} value - The value associated with the field.
 *
 * @typedef {Object} Section
 * @property {string} [title] - The title of the section (optional).
 * @property {string} [description] - A description for the section (optional).
 * @property {Field[]} fields - A list of fields within the section.
 *
 * @property {string} title - The main title of the data structure.
 * @property {string} icon - The icon associated with the data structure.
 * @property {Section[]} sections - A list of sections included in the data structure.
 */
export default {
  name: 'AppSectionCard',
  props: {
    title: {
      type: String,
      default: 'Basic application details'
    },
    icon: {
      type: String,
      default: 'mdi-account'
    },
    sections: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  methods: {
    isDisplaySection(section) {
      return section.title || section.description
    },
    isNoDescriptionTitle(section) {
      return !section.description && !section.title
    }
  }
}
</script>

<style lang="scss" scoped>
.section {
  color: $ads-grey-01;
}

.section-card-title {
  font-weight: bold;
  color: $ads-primary-blue;
}

.section-card {
  padding: 36px 48px 12px;
  margin-bottom: 32px;

  @media print {
    box-shadow: none !important;
  }
}

.section-avatar {
  background-color: $ads-primary-teal;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

.section-field:not(:last-child) {
  margin-bottom: 38px;
}

.field-label {
  font-weight: bold;
  color: $ads-primary-blue;
  font-size: 14px;
}

.empty-value {
  opacity: 0.5;
}
</style>
