<template>
  <div>
    <AppModal
      v-if="showRefreshListConfirmModal"
      ref="AppModal"
      data-testid="refresh-list-confirm-modal"
    >
      <template slot="header">
        <div class="heading">
          <v-avatar size="40" color="primary lighten-4" class="mr-4">
            <v-icon color="#002664">mdi-account-check-outline</v-icon>
          </v-avatar>
          <span class="font-weight-bold">Refresh unlinked students list?</span>
          <v-icon
            ref="closeButton"
            @click="$emit('close')"
            class="text-right closeBtn"
            aria-label="Close"
            data-testid="close-icon"
          >
            mdi-close
          </v-icon>
        </div>
      </template>
      <div slot="body">
        <div class="content">
          <p>You are about to refresh the unlinked students list.</p>
          <p>
            This process can take a few minutes to complete. You will be unable
            to use this list during the refresh.
          </p>
        </div>
      </div>
      <div slot="footer">
        <AdsButton
          primary
          :ripple="false"
          :button-text="`Refresh List`"
          data-testid="confirm-btn"
          @click="$emit('confirm')"
        />
        <AdsButton
          class="button"
          tertiary
          button-text="Cancel"
          @click="$emit('close')"
          data-testid="cancel-btn"
        />
      </div>
    </AppModal>
  </div>
</template>

<script>
import AppModal from '@/components/app/AppModal.vue'
import { AdsButton } from '@nswdoe/doe-ui-core'

export default {
  name: 'Y67TPSRefreshListModal',
  components: {
    AppModal,
    AdsButton
  },
  data() {
    return {
      showRefreshListConfirmModal: true
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .AppModal {
  text-align: left;
  white-space: normal;
  .box {
    padding: 1rem;
    max-width: 550px;
  }
  .heading {
    font-size: 1.3333rem;
    font-weight: 700;
    margin-top: -1rem;
  }
  .body {
    font-size: 1.0666rem;
    line-height: 1.6rem;
  }
}
::v-deep .header {
  flex-direction: column;
}
.heading {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
}
.closeBtn {
  margin: 0 -0.5rem 0 auto;
  border: none;
  padding: 0;
  &:hover {
    text-decoration: none !important;
  }
}
.footer {
  text-align: right;
}
.theme--light.v-icon.v-icon--link {
  color: $color-dark70;
  &:focus {
    color: $color-primary;
    outline: 3px solid $color-primary;
    border: none;
  }
}
</style>
