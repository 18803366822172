import { OOA_CRITERIA } from '@/constants'

const sortValues = (arr) => {
  return arr.sort((a, b) => {
    if (a === null) return -1 // Nulls first
    if (b === null) return 1
    if (a === '') return -1 // Empty strings next
    if (b === '') return 1
    return a > b ? 1 : a < b ? -1 : 0 // Generic comparison
  })
}

const searchFilter = (sourceStrs, searchStr) => {
  // check that all searchStr words 'starts with' in at least one of the source strings
  // e.g. searching 'ab de' should match a record with values ['abc', 'def']
  // Sort source strings so that empty strings come first if ascending order is needed
  const sourceStrings = sortValues(sourceStrs.filter((s) => s != null))

  const searchStrings = searchStr.split(' ').filter((f) => f !== '')
  return searchStrings.every((ss) => {
    return (
      sourceStrings.findIndex((source) =>
        source
          .toLowerCase()
          .split(' ')
          .some((s) => s.startsWith(ss.toLowerCase()))
      ) != -1
    )
  })
}

const searchFilterForStringOnly = (value, search, item) => {
  return (
    value != null &&
    search != null &&
    item != null &&
    value === item.name &&
    typeof value === 'string' &&
    searchFilter(
      value.split(',').map((v) => v.trim()),
      search
    )
  )
}

const sortScholasticYearCodes = (years) => {
  const sortOrder = [
    'K',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12'
  ]

  // convert [Kindergarten, Year 1, Year 2] to [K, 1, 2]
  const yearCodes = years.map((y) => {
    return y === 'Kindergarten' ? 'K' : y.split(' ')[1]
  })
  return yearCodes.sort(function (a, b) {
    return sortOrder.indexOf(a) - sortOrder.indexOf(b)
  })
}

const setScholasticYear = (item) => {
  if (item.scholasticYear === 'K') {
    item.scholasticYear = 'Kindergarten'
  }
  if (item.scholasticYear === '1') {
    item.scholasticYear = 'Year 1'
  }
  if (item.scholasticYear === '2') {
    item.scholasticYear = 'Year 2'
  }
  if (item.scholasticYear === '3') {
    item.scholasticYear = 'Year 3'
  }
  if (item.scholasticYear === '4') {
    item.scholasticYear = 'Year 4'
  }
  if (item.scholasticYear === '5') {
    item.scholasticYear = 'Year 5'
  }
  if (item.scholasticYear === '6') {
    item.scholasticYear = 'Year 6'
  }
  if (item.scholasticYear === '7') {
    item.scholasticYear = 'Year 7'
  }
  if (item.scholasticYear === '8') {
    item.scholasticYear = 'Year 8'
  }
  if (item.scholasticYear === '9') {
    item.scholasticYear = 'Year 9'
  }
  if (item.scholasticYear === '10') {
    item.scholasticYear = 'Year 10'
  }
  if (item.scholasticYear === '11') {
    item.scholasticYear = 'Year 11'
  }
  if (item.scholasticYear === '12') {
    item.scholasticYear = 'Year 12'
  }
}

const sortByScholasticYear = [
  'Kindergarten',
  'Year 1',
  'Year 2',
  'Year 3',
  'Year 4',
  'Year 5',
  'Year 6',
  'Year 7',
  'Year 8',
  'Year 9',
  'Year 10',
  'Year 11',
  'Year 12'
]

// Replace 'MED' with 'LAW'
const replaceCriteriaMedToLaw = (applicationCriteria = []) =>
  applicationCriteria.map((item) => {
    if (item.code === OOA_CRITERIA.MED) {
      return { ...item, code: OOA_CRITERIA.LAW }
    }
    return item
  })

export {
  searchFilter,
  searchFilterForStringOnly,
  sortScholasticYearCodes,
  setScholasticYear,
  sortByScholasticYear,
  replaceCriteriaMedToLaw
}
