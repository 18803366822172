<template>
  <!--
    'persistent' and 'no-click-animation' props are a work-around for
    modal mysteriously closing when pressing down on a select box
  -->
  <v-dialog
    class="app-settings"
    v-model="valueInternal"
    fullscreen
    persistent
    no-click-animation
    hide-overlay
    transition="dialog-bottom-transition"
    origin="center bottom"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        v-if="cog"
        icon
        dark
        class="mr-3 settingsIcon circular-btn"
        aria-label="Settings"
        v-bind="attrs"
        v-on="on"
        :disabled="isEditing"
        data-testid="app-settings-cog"
        @click="settingsCogClicked"
        ref="settingsCog"
      >
        <v-icon medium>mdi-cog-outline</v-icon>
      </v-btn>
      <v-btn
        v-if="!cog"
        text
        color="#041E42"
        class="settings-link"
        aria-label="OPEN SCHOOL SETTINGS"
        v-bind="attrs"
        v-on="on"
        data-testid="app-settings-cog"
        @click="settingsCogClicked"
        ref="settingsCog"
        >school settings
      </v-btn>
    </template>
    <v-card rounded="0">
      <div tabindex="0" ref="appSettingsModalPreFocus" />
      <div
        class="d-flex align-center app-settings__header"
        :class="{ 'app-settings__header-ie': isIe }"
        :aria-hidden="displayChangedModal || displaySaveCloseModal"
      >
        <span
          id="closeButtonLabel"
          class="d-sr-only"
          aria-label="Close enrolment settings"
        />
        <v-btn
          icon
          text
          class="white--text mr-3"
          @click="close"
          aria-labelledby="closeButtonLabel"
          data-testid="app-settings-modal-close"
          ref="closeWithoutSave"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <h1>School online enrolment settings</h1>
        <v-spacer />
        <AdsButton
          class="app-settings__save-button mr-6 primary--text"
          v-if="
            haveAnySettingsChanged && !showAlertErrorForUnableToRetrieveSettings
          "
          color="tertiary"
          @click="handleSave"
          data-testid="app-settings-modal-save"
          icon="save_alt"
          button-text="Save &amp; Close"
        />
      </div>

      <div
        class="app-settings__content pb-13"
        v-if="loaded"
        :aria-hidden="displayChangedModal || displaySaveCloseModal"
      >
        <v-container>
          <AdsDialog
            title="Close settings without saving?"
            max-width="550px"
            :open-dialog="displayChangedModal"
            display-close-btn
            message="Select <b>Discard Changes</b> to close this window without saving.<br/>To save your changes, select <b>Cancel</b>, then select the <b>Save &amp; Close</b> button."
            :actions="displayChangedActionList"
            @close="displayChangedModal = false"
            :return-handler="handleCloseWithoutSaving"
            data-testid="close-without-saving-dialog"
            id="close-without-saving"
          />
        </v-container>
        <v-container>
          <DialogModal
            v-if="displaySaveCloseModal"
            :is-persistent="true"
            @close="displaySaveCloseModal = false"
            :dialog-width="650"
          >
            <div slot="header">
              <span
                data-testid="app-settings-heading"
                class="heading"
                role="alert"
              >
                Please confirm these changes have been approved
              </span>
            </div>
            <div slot="body">
              <OesCheckbox
                v-model="isPrincipalApproved"
                class="mr-1"
                data-testid="app-settings-saveclose-checkbox"
                label="I confirm that the school principal has seen and approved these changes"
              />
            </div>
            <div slot="footer">
              <AdsButton
                @click="cancelSave"
                class="ma-2"
                large
                secondary
                data-testid="app-settings-saveclose-cancel"
                button-text="Cancel"
              />
              <AdsButton
                @click="save"
                class="ma-2"
                primary
                large
                :disabled="!isPrincipalApproved"
                data-testid="app-settings-saveclose-confirm"
                button-text="Confirm changes"
              />
            </div>
          </DialogModal>
        </v-container>

        <div class="d-flex align-start pa-6 pa-md-12">
          <div
            class="app-settings__circle-icon d-flex align-center justify-center"
          >
            <v-icon size="30">mdi-cog-outline</v-icon>
          </div>
          <div class="ml-3">
            <h2 data-testid="app-settings-heading" tabindex="0">
              {{ school.schoolName }} online enrolment settings
            </h2>
            <p class="subtitle mt-2">
              Adjust your school's current enrolment settings below. Select
              <strong>Save &amp; Close</strong> to save changes.
            </p>
          </div>

          <v-spacer />
          <div
            class="d-flex align-center app-settings__last-updated"
            v-if="lastUpdatedBy && lastUpdatedDate"
          >
            <v-icon>mdi-history</v-icon>
            <p class="mb-0 ml-1" data-testid="app-settings-lastupdated-text">
              Last updated by: {{ lastUpdatedBy }} {{ lastUpdatedDate }}
            </p>
          </div>
        </div>

        <TabNavigation
          class="pr-12"
          :value="settingsTab"
          :vertical="true"
          :items="getSettingsTabItems"
          text-transform="none"
          :hide-slider="true"
          data-testid="settings-tab"
          @change="getItemName"
          @tabChange="settingsTab = $event"
        >
          <template slot="content1">
            <Alert
              v-if="showAlertErrorForUnableToRetrieveSettings"
              in-page
              text="Unable to retrieve enrolment settings"
              subtext="Please refresh the page to try again."
              type="error"
              class="ml-6 pb-2"
            />
            <Alert
              v-if="haveSreSeeErrors"
              in-page
              :text="sreSeeAlertText"
              :subtext="sreSeeAlertSubtext"
              type="error"
              class="ml-6 pb-2"
            />
            <Alert
              v-if="duplicateOptionError"
              in-page
              :text="duplicationErrorText"
              :subtext="duplicationErrorSubText"
              type="error"
              class="ml-6 pb-2"
            />
            <v-card class="ml-6">
              <AppSettingsParentCommsLocal
                :school="school"
                :who-will-contact="whoWillContact"
                :current-year-time-frame="currentYearTimeFrameLocal"
                :current-year-time-frames="currentYearTimeFramesLocal"
                :next-year-time-frame="nextYearTimeFrameLocal"
                :next-year-time-frames="nextYearTimeFramesLocal"
                @updatedWhoWillContact="setWhoWillContact"
                @updatedCurrentYearTimeFrameLocal="setCurrentYearTimeFrameLocal"
                @updatedNextYearTimeFrameLocal="setNextYearTimeFrameLocal"
              />
              <AppSettingsParentCommsOOA
                v-if="isOOASchool"
                :current-year-time-frame="currentYearTimeFrame"
                :current-year-time-frames="currentYearTimeFrames"
                :next-year-time-frame="nextYearTimeFrame"
                :next-year-time-frames="nextYearTimeFrames"
                @updatedCurrentYearTimeFrame="setCurrentYearTimeFrame"
                @updatedNextYearTimeFrame="setNextYearTimeFrame"
              />
            </v-card>
          </template>
          <template
            :slot="`content${isOOASchool || isY67THighSchoolOnly ? 2 : 9}`"
            v-if="isOOASchool || isY67THighSchoolOnly"
          >
            <Alert
              v-if="showAlertErrorForUnableToRetrieveSettings"
              in-page
              text="Unable to retrieve enrolment settings"
              subtext="Please refresh the page to try again."
              type="error"
              class="ml-6 pb-2"
            />
            <Alert
              v-if="haveSreSeeErrors"
              in-page
              :text="sreSeeAlertText"
              :subtext="sreSeeAlertSubtext"
              type="error"
              class="ml-6 pb-2"
            />
            <Alert
              v-if="duplicateOptionError"
              in-page
              :text="duplicationErrorText"
              :subtext="duplicationErrorSubText"
              type="error"
              class="ml-6 pb-2"
            />
            <v-card class="ml-6">
              <AppSettingsSchoolCap
                :capacity="capacity"
                :capacities="capacities"
                :scout-capacity="scoutCapacity"
                @updatedCapacity="setCapacity"
              />
              <AppSettingsSelectionCriteria
                v-model="selectedValue"
                :school="school"
                :capacity="capacity"
                :selection-criteria="selectionCriteria"
                :other-considerations="otherConsiderations"
                @updatedOtherConsiderations="setOtherConsiderations"
              />
            </v-card>
          </template>
          <template
            :slot="`content${isOOASchool || isY67THighSchoolOnly ? 3 : 2}`"
            v-if="isSREEnableSchool"
          >
            <div class="SREWrapper">
              <Alert
                v-if="sreSeeNeedsUpdate"
                in-page
                :text="sreSeeNeedsUpdateText"
                :subtext="sreSeeNeedsUpdateSubText"
                type="error"
                class="pb-2"
              />
              <Alert
                v-if="showAlertErrorForUnableToRetrieveSettings"
                in-page
                text="Unable to retrieve enrolment settings"
                subtext="Please refresh the page to try again."
                type="error"
                class="pb-2"
              />
              <Alert
                v-if="haveSreSeeErrors"
                in-page
                :text="sreSeeAlertText"
                :subtext="sreSeeAlertSubtext"
                type="error"
                class="pb-2"
              />
              <Alert
                v-if="duplicateOptionError"
                in-page
                :text="duplicationErrorText"
                :subtext="duplicationErrorSubText"
                type="error"
                class="pb-2"
              />
              <v-card>
                <AppSettingsSreSeeUrl
                  :disabled="isSuperAdmin"
                  :saved-sre-see-url="schoolSreSeeUrl"
                  :school-name="school.schoolName"
                  @updatedSreSeeSchoolUrl="setSreSeeAmaSchoolUrl"
                  @validateForm="showErrorsOrShowDialog"
                />
              </v-card>
              <v-card>
                <AppSettingsSreOptions
                  :disabled="isSuperAdmin"
                  :default-sre-options="defaultSreSeeOptions.sre"
                  :saved-sre-options="sreOptions"
                  @updatedSreOptions="setSreOptions"
                  :available-scholastic-years="scholasticYears"
                  @duplicateSreOption="setDuplicateOption"
                  @validateForm="showErrorsOrShowDialog"
                />
              </v-card>
              <v-card>
                <AppSettingsSeeOptions
                  :disabled="isSuperAdmin"
                  :default-see-options="defaultSreSeeOptions.see"
                  :saved-see-options="seeOptions"
                  @updatedSeeOptions="setSeeOptions"
                  :available-scholastic-years="scholasticYears"
                  @duplicateSeeOption="setDuplicateOption"
                  @validateForm="showErrorsOrShowDialog"
                />
              </v-card>
              <v-card v-if="isDisplayAmaOptions">
                <AppSettingsAmaOption
                  :disabled="isSuperAdmin"
                  :default-ama-options="defaultSreSeeOptions.ama"
                  :saved-ama-options="amaOptions"
                  @updatedAmaOptions="setAmaOptions"
                />
              </v-card>
              <div class="saveCloseBtn">
                <AdsButton
                  class="app-settings__save-button settingsPrimary"
                  v-if="
                    haveAnySettingsChanged &&
                    isSelectSEETab &&
                    !showAlertErrorForUnableToRetrieveSettings
                  "
                  @click="handleSave"
                  data-testid="app-settings-modal-save"
                  icon="save_alt"
                  button-text="Save &amp; Close"
                />
              </div>
            </div>
          </template>
        </TabNavigation>

        <div class="full-width pa-6 pa-md-12">
          <AdsButton
            class="app-settings__save-button settingsPrimary"
            v-if="
              haveAnySettingsChanged &&
              !isSelectSEETab &&
              !showAlertErrorForUnableToRetrieveSettings
            "
            @click="handleSave"
            data-testid="app-settings-modal-save"
            icon="save_alt"
            button-text="Save &amp; Close"
          />
        </div>

        <div aria-live="polite" class="mt-6 mt-md-12 sr-only">
          <p>{{ ariaAnnouncement }}</p>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { AdsButton, Alert, Dialog } from '@nswdoe/doe-ui-core'
import TabNavigation from './TabNavigation.vue'
import { mapGetters } from 'vuex'
import _isEqual from 'lodash/isEqual'
import OesCheckbox from '@/components/app/OesCheckbox'
import { isIe } from '@/helpers/generalUtils'
import AppSettingsParentCommsLocal from './AppSettingsParentCommsLocal.vue'
import AppSettingsParentCommsOOA from './AppSettingsParentCommsOOA.vue'
import AppSettingsSchoolCap from './AppSettingsSchoolCap.vue'
import AppSettingsSreOptions from './AppSettingsSREOptions.vue'
import AppSettingsSeeOptions from './AppSettingsSEEOptions.vue'
import AppSettingsAmaOption from './AppSettingsAmaOption.vue'
import AppSettingsSelectionCriteria from './AppSettingsSelectionCriteria.vue'
import AppSettingsSreSeeUrl from './AppSettingsSreSeeUrl.vue'
import settingsUtils from '@/helpers/settingsUtils'
import DialogModal from '@/components/app/DialogModal.vue'
import validators from '@/helpers/validators'
import API from '@/store/apiUtils'
import UTILS from '@/store/utils'

const DISCARD_MODAL_OPTIONS = {
  CANCEL: 'Cancel',
  DISCARD_CHANGES: 'Discard changes'
}

const initialState = () => {
  return {
    isIe: isIe(),
    loaded: false,
    valueInternal: true,
    whoWillContact: null,
    currentYearTimeFrame: null,
    currentYearTimeFrameLocal: null,
    nextYearTimeFrame: null,
    nextYearTimeFrameLocal: null,
    capacity: null,
    scoutCapacity: null,
    selectionCriteria: null,
    otherConsiderations: true,
    selectionCriteriaChanged: false,
    originalSettings: null,
    originalCriteria: null,
    saveAttempted: false,
    displayChangedCloseBtn: true,
    displaySaveCloseBtn: true,
    displayChangedModal: false,
    displaySaveCloseModal: false,
    selectedValue: null,
    displayChangedActionList: [
      {
        name: DISCARD_MODAL_OPTIONS.CANCEL,
        color: 'primary',
        size: 'large',
        outlined: true
      },
      {
        name: DISCARD_MODAL_OPTIONS.DISCARD_CHANGES,
        color: 'primary',
        size: 'large',
        outlined: false
      }
    ],
    isPrincipalApproved: false,
    lastUpdatedBy: null,
    lastUpdatedDate: null,
    ariaAnnouncement: '',
    settingsTabItems: [
      { tab: 'Parent<br/>communication', icon: 'question_answer' },
      {
        tab: 'School cap<br/>& selection criteria',
        icon: 'playlist_add_check'
      },
      { tab: 'SRE/SEE options', icon: 'mdi-human-male-board' }
    ],
    y67tInfo: null,
    isSelectSEETab: false,
    schoolSreSeeUrl: null,
    sreOptions: null,
    seeOptions: null,
    amaOptions: null,
    originalSchoolSreSeeUrl: null,
    originalSreOptions: null,
    originalSeeOptions: null,
    originalAmaOptions: null,
    sreSeeErrors: null,
    validators: {
      isValidURL: validators.isValidURL
    },
    sreSeeAlertText: 'Unable to save enrolment settings',
    sreSeeAlertSubtext:
      'Please complete the missing SRE/SEE options information to save your settings.',
    showAlertErrorForUnableToRetrieveSettings: false,
    duplicationErrorText: 'Duplication error',
    duplicationErrorSubText:
      'Please ensure the Title, Provider(s) and Scholastic Year(s) are unique for each SRE/SEE option.',
    duplicateOptionError: false,
    sreSeeNeedsUpdate: false,
    sreSeeNeedsUpdateText: 'Your SRE/SEE options need to be updated',
    sreSeeNeedsUpdateSubText:
      'The list of available SRE/SEE options and/or approved providers has been updated. One or more of your configured options can no longer be offered. Please update your settings.',
    sreAvailableReligions: [],
    seeAvailableReligions: [],
    amaAvailableReligions: []
  }
}

export default {
  name: 'AppSettingsModal',
  components: {
    AdsButton,
    Alert,
    AdsDialog: Dialog,
    DialogModal,
    OesCheckbox,
    TabNavigation,
    AppSettingsParentCommsLocal,
    AppSettingsParentCommsOOA,
    AppSettingsSchoolCap,
    AppSettingsSelectionCriteria,
    AppSettingsSreOptions,
    AppSettingsSeeOptions,
    AppSettingsAmaOption,
    AppSettingsSreSeeUrl
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    cog: {
      type: Boolean,
      default: true
    },
    school: {
      type: Object,
      required: true
    }
  },
  data() {
    return initialState()
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.valueInternal = val
        if (val) {
          this.init()
        }
      }
    },
    valueInternal(val) {
      this.$emit('input', val)
    },
    displaySaveCloseModal(val) {
      if (!val && this.confirmSaveBtn) {
        const btn = this.confirmSaveBtn
        setTimeout(function () {
          btn.focus()
        }, 100)
      }
    },
    displayChangedModal(val) {
      if (!val) {
        const closeBtn = this.$refs.closeWithoutSave.$el
        setTimeout(function () {
          closeBtn.focus()
        }, 100)
      }
    }
  },
  computed: {
    ...mapGetters([
      'currentYearTimeFrames',
      'currentYearTimeFramesLocal',
      'nextYearTimeFrames',
      'nextYearTimeFramesLocal',
      'defaultSelectionCriteria',
      'capacities',
      'defaultSreSeeOptions',
      'isEditing'
    ]),
    settingsTab: {
      get: function () {
        return this.$store.state.settingsTab
      },
      set: function (value) {
        this.$store.commit('set', ['settingsTab', value])
      }
    },
    isSuperAdmin() {
      return settingsUtils.isSuperAdmin()
    },
    isDisplayAmaOptions() {
      return !!this.sreOptions || !!this.seeOptions
    },
    scholasticYears() {
      return settingsUtils.getScholasticYears(this.school.catchmentLevel)
    },
    prevCapStatus() {
      return this.$store.state.selectedSchool.prevCapStatus
    },
    haveAnySettingsChanged() {
      const haveSettingsChanged = !_isEqual(
        this.getSettings(),
        this.originalSettings
      )
      const haveCriteriaChanged = !_isEqual(
        this.selectionCriteria,
        this.originalCriteria
      )
      const haveSchoolSreSeeUrlChanged = !_isEqual(
        this.schoolSreSeeUrl,
        this.originalSchoolSreSeeUrl
      )
      const haveSreOptionsChanged = !_isEqual(
        this.sreOptions,
        this.originalSreOptions
      )
      const haveSeeOptionsChanged = !_isEqual(
        this.seeOptions,
        this.originalSeeOptions
      )

      const haveAmaOptionsChanged = !_isEqual(
        this.amaOptions,
        this.originalAmaOptions
      )

      return (
        this.loaded &&
        (haveSchoolSreSeeUrlChanged ||
          haveSreOptionsChanged ||
          haveSeeOptionsChanged ||
          haveAmaOptionsChanged ||
          haveSettingsChanged ||
          haveCriteriaChanged) &&
        !this.isSuperAdmin
      )
    },
    getSettingsTabItems() {
      const settingsTabArray = [this.settingsTabItems[0]]

      if (this.isOOASchool || this.isY67THighSchoolOnly) {
        settingsTabArray.push(this.settingsTabItems[1])
      }

      if (this.isSREEnableSchool) {
        settingsTabArray.push(this.settingsTabItems[2])
      }

      return settingsTabArray
    },
    isHighSchool() {
      return settingsUtils.isHighSchool(this.school)
    },
    isY67THighSchoolOnly() {
      return settingsUtils.isY67THighSchoolOnly(this.school)
    },
    isSREEnableSchool() {
      return this.school.sre_see
    },
    isOOASchool() {
      return settingsUtils.isOOASchool(this.school)
    },
    haveSreSeeErrors() {
      const haveSreError =
        this.saveAttempted &&
        this.sreSeeErrors &&
        (this.sreSeeErrors.sre.name.length > 0 ||
          this.sreSeeErrors.sre.providers.length > 0 ||
          this.sreSeeErrors.sre.scholasticYears.length > 0 ||
          this.sreSeeErrors.sre.curriculumLink.length > 0 ||
          this.sreSeeErrors.sre.description.length > 0)
      const haveSeeError =
        this.saveAttempted &&
        this.sreSeeErrors &&
        (this.sreSeeErrors.see.name.length > 0 ||
          this.sreSeeErrors.see.providers.length > 0 ||
          this.sreSeeErrors.see.scholasticYears.length > 0 ||
          this.sreSeeErrors.see.curriculumLink.length > 0 ||
          this.sreSeeErrors.see.description.length > 0)
      return haveSreError || haveSeeError || this.duplicateOptionError
    }
  },
  methods: {
    handleSave(e) {
      this.saveAttempted = true
      if (this.showErrorsOrShowDialog(e)) {
        this.saveAttempted = false
        this.displaySaveCloseModal = true
        this.confirmSaveBtn =
          e.srcElement.tagName === 'SPAN'
            ? e.srcElement.parentElement
            : e.srcElement
      }
    },
    showErrorsOrShowDialog(e) {
      this.$eventHub.$emit('validate')
      const sreSeeErrors = {}

      let nameIndex = []
      let providersIndex = []
      let scholasticYearsIndex = []
      let curriculumLinkIndex = []
      let descriptionLinkIndex = []

      this.sreSeeNeedsUpdate = false

      if (this.defaultSreSeeOptions?.sre) {
        this.sreAvailableReligions = this.defaultSreSeeOptions.sre.map(
          ({ name }) => name
        )
        this.seeAvailableReligions = this.defaultSreSeeOptions.see.map(
          ({ name }) => name
        )
        this.amaAvailableReligions = this.defaultSreSeeOptions.ama.map(
          ({ name }) => name
        )
      }

      for (let i in this.sreOptions) {
        if (!this.sreOptions[i].name) {
          nameIndex.push(Number(i))
        } else if (
          validators.titleExists(
            this.sreOptions[i],
            this.sreAvailableReligions
          ) !== true
        ) {
          this.sreSeeNeedsUpdate = true
          nameIndex.push(Number(i))
        }

        if (
          !this.sreOptions[i].providers ||
          (Array.isArray(this.sreOptions[i].providers) &&
            this.sreOptions[i].providers.length === 0)
        ) {
          providersIndex.push(Number(i))
        } else {
          const sreAvailableProviders = this.defaultSreSeeOptions?.sre?.filter(
            (option) => {
              if (this.sreOptions[i].name === option.name) {
                return option.providers.map(({ name }) => name)
              }
            }
          )

          if (sreAvailableProviders.length > 0) {
            if (
              validators.providersExists(
                this.sreOptions[i].providers,
                sreAvailableProviders[0]?.providers.map(({ name }) => name)
              ) !== true
            ) {
              this.sreSeeNeedsUpdate = true
              providersIndex.push(Number(i))
            }
          }
        }
        if (
          (this.sreOptions[i].curriculumLink &&
            validators.isValidURL(this.sreOptions[i].curriculumLink) ===
              'Invalid URL') ||
          !this.sreOptions[i].curriculumLink
        ) {
          curriculumLinkIndex.push(Number(i))
        }
        if (!this.sreOptions[i].description) {
          descriptionLinkIndex.push(Number(i))
        }
        if (
          !this.sreOptions[i].scholasticYears ||
          (Array.isArray(this.sreOptions[i].scholasticYears) &&
            this.sreOptions[i].scholasticYears.length === 0)
        ) {
          scholasticYearsIndex.push(Number(i))
        }
      }

      sreSeeErrors.sre = {
        name: nameIndex,
        providers: providersIndex,
        scholasticYears: scholasticYearsIndex,
        curriculumLink: curriculumLinkIndex,
        description: descriptionLinkIndex
      }

      nameIndex = []
      providersIndex = []
      scholasticYearsIndex = []
      curriculumLinkIndex = []
      descriptionLinkIndex = []
      for (let i in this.seeOptions) {
        if (!this.seeOptions[i].name) {
          nameIndex.push(Number(i))
        } else if (
          validators.titleExists(
            this.seeOptions[i],
            this.seeAvailableReligions
          ) !== true
        ) {
          this.sreSeeNeedsUpdate = true
          nameIndex.push(Number(i))
        }
        if (
          !this.seeOptions[i].providers ||
          (Array.isArray(this.seeOptions[i].providers) &&
            this.seeOptions[i].providers.length === 0)
        ) {
          providersIndex.push(Number(i))
        } else {
          const seeAvailableProviders = this.defaultSreSeeOptions?.see?.filter(
            (option) => {
              if (this.seeOptions[i].name === option.name) {
                return option.providers.map(({ name }) => name)
              }
            }
          )

          if (seeAvailableProviders.length > 0) {
            if (
              validators.providersExists(
                this.seeOptions[i].providers,
                seeAvailableProviders[0]?.providers.map(({ name }) => name)
              ) !== true
            ) {
              this.sreSeeNeedsUpdate = true
              providersIndex.push(Number(i))
            }
          }
        }

        if (
          (this.seeOptions[i].curriculumLink &&
            validators.isValidURL(this.seeOptions[i].curriculumLink) ===
              'Invalid URL') ||
          !this.seeOptions[i].curriculumLink
        ) {
          curriculumLinkIndex.push(Number(i))
        }
        if (
          !this.seeOptions[i].scholasticYears ||
          (Array.isArray(this.seeOptions[i].scholasticYears) &&
            this.seeOptions[i].scholasticYears.length === 0)
        ) {
          scholasticYearsIndex.push(Number(i))
        }
      }
      sreSeeErrors.see = {
        name: nameIndex,
        providers: providersIndex,
        scholasticYears: scholasticYearsIndex,
        curriculumLink: curriculumLinkIndex,
        description: descriptionLinkIndex
      }

      this.sreSeeErrors = sreSeeErrors
      this.duplicateOptionError =
        settingsUtils.checkDuplicateOption(this.sreOptions) ||
        settingsUtils.checkDuplicateOption(this.seeOptions)

      return !this.haveSreSeeErrors && !this.duplicateOptionError && e
    },
    setDuplicateOption(isDupe) {
      this.duplicateOptionError = isDupe
    },
    setSreSeeAmaSchoolUrl(url) {
      this.schoolSreSeeUrl = url
    },
    setSreOptions(options) {
      this.sreOptions = null
      if (options && options.length > 0) {
        const savedOptions = []
        options.map((saved) => {
          const option = {
            id: saved.id,
            guid: saved.guid,
            name: saved.name,
            providers: saved.providers,
            description: UTILS.sanitize(saved.description),
            scholasticYears: saved.scholasticYears,
            curriculumLink: saved.curriculumLink
          }
          savedOptions.push(option)
        })
        this.sreOptions = savedOptions
      }
      if (!this.amaOptions) {
        if (
          this.defaultSreSeeOptions &&
          this.defaultSreSeeOptions.ama &&
          this.defaultSreSeeOptions.ama.length > 0
        ) {
          const defaultAmaOption = this.defaultSreSeeOptions.ama[0]
          this.amaOptions = [
            {
              id: defaultAmaOption ? defaultAmaOption.id : '',
              guid: defaultAmaOption ? defaultAmaOption.guid : '',
              name: defaultAmaOption ? defaultAmaOption.name : '',
              providers: defaultAmaOption ? defaultAmaOption.providers : '',
              description: defaultAmaOption
                ? UTILS.sanitize(defaultAmaOption.defaultDescription)
                : ''
            }
          ]
        }
      } else {
        if (this.amaOptions && this.amaOptions[0] && !this.amaOptions[0].guid) {
          this.amaOptions[0].guid = API.getGuid()
        }
      }
    },
    setSeeOptions(options) {
      this.seeOptions = null
      if (options && options.length > 0) {
        const savedOptions = []
        options.map((saved) => {
          const option = {
            id: saved.id,
            guid: saved.guid,
            name: saved.name,
            providers: saved.providers,
            description: UTILS.sanitize(saved.description),
            scholasticYears: saved.scholasticYears,
            curriculumLink: saved.curriculumLink
          }
          savedOptions.push(option)
        })
        this.seeOptions = savedOptions
      }
      if (!this.amaOptions) {
        if (
          this.defaultSreSeeOptions &&
          this.defaultSreSeeOptions.ama &&
          this.defaultSreSeeOptions.ama.length > 0
        ) {
          const defaultAmaOption = this.defaultSreSeeOptions.ama[0]
          this.amaOptions = [
            {
              id: defaultAmaOption ? defaultAmaOption.id : '',
              guid: defaultAmaOption ? defaultAmaOption.guid : '',
              name: defaultAmaOption ? defaultAmaOption.name : '',
              providers: defaultAmaOption ? defaultAmaOption.providers : '',
              description: defaultAmaOption
                ? UTILS.sanitize(defaultAmaOption.defaultDescription)
                : ''
            }
          ]
        }
      } else {
        if (this.amaOptions && this.amaOptions[0] && !this.amaOptions[0].guid) {
          this.amaOptions[0].guid = API.getGuid()
        }
      }
    },
    setAmaOptions(options) {
      this.amaOptions = [
        {
          id: options.id,
          guid: options.guid ? options.guid : API.getGuid(),
          name: options.name,
          providers: options.providers,
          description: UTILS.sanitize(options.description)
        }
      ]
    },
    getItemName(item) {
      this.isSelectSEETab = item == 2
    },
    setCurrentYearTimeFrame(obj) {
      this.currentYearTimeFrame = obj
    },
    setNextYearTimeFrame(obj) {
      this.nextYearTimeFrame = obj
    },
    setCapacity(obj) {
      this.capacity = obj
    },
    setOtherConsiderations(obj) {
      this.otherConsiderations = obj
    },
    setWhoWillContact(obj) {
      this.whoWillContact = obj
    },
    setCurrentYearTimeFrameLocal(obj) {
      this.currentYearTimeFrameLocal = obj
    },
    setNextYearTimeFrameLocal(obj) {
      this.nextYearTimeFrameLocal = obj
    },
    getSettings() {
      return [
        this.whoWillContact,
        this.currentYearTimeFrame,
        this.currentYearTimeFrameLocal,
        this.nextYearTimeFrame,
        this.nextYearTimeFrameLocal,
        this.capacity,
        this.otherConsiderations
      ]
    },
    settingsCogClicked() {
      this.$emit('opened', true)
      this.$gtm.trackEvent({
        category: 'settings',
        action: 'settingsCogClick',
        label: this.school.schoolName
      })
    },
    close() {
      if (this.haveAnySettingsChanged) {
        this.displayChangedModal = true
      } else {
        this.closeAppSettingsModal()
      }
    },
    closeAppSettingsModal() {
      this.valueInternal = false
      this.$emit('opened', false)

      const cogButton = this.$refs.settingsCog.$el
      setTimeout(function () {
        cogButton.focus()
      }, 300)
    },
    newCriteriaFromDefault() {
      return this.defaultSelectionCriteria
        .filter((c) => c.active === true)
        .sort((c1, c2) => (c1.defaultOrder < c2.defaultOrder ? -1 : 1))
        .map((c) => ({
          ...c,
          selected: c.mandatory === true
        }))
    },
    newSelectionCriteria() {
      return this.defaultSelectionCriteria
        .filter((c) => c.active === true)
        .sort((c1, c2) => (c1.defaultOrder < c2.defaultOrder ? -1 : 1))
        .map((c) => ({
          ...c,
          description:
            c.code === 'LAW' || c.code === 'SIB' || c.code === 'STA'
              ? ''
              : c.description,
          selected: c.mandatory === true
        }))
    },
    handleCloseWithoutSaving(actionName) {
      this.displayChangedModal = false
      if (actionName === DISCARD_MODAL_OPTIONS.DISCARD_CHANGES) {
        // Discard Changes (close this modal, and then the other modal)
        this.closeAppSettingsModal()
      }
    },
    cancelSave() {
      this.displaySaveCloseModal = false
      this.isPrincipalApproved = false
    },
    async save() {
      const criteria = this.selectionCriteria.map((s, i) => {
        return {
          code: s.code,
          order: i + 1,
          enabled: s.selected,
          description: s.selected ? s.description : ''
        }
      })

      const settings = {
        schoolCode: this.school.schoolCode,
        ooa: {
          criteria,
          enableOtherCriteria: this.otherConsiderations,
          currentYearTimeFrames: { code: this.currentYearTimeFrame.code },
          nextYearTimeFrames: { code: this.nextYearTimeFrame.code },
          capacity: { code: this.capacity }
        },
        local: {
          whoWillContact: this.whoWillContact,
          currentYearTimeFrames: {
            code: this.currentYearTimeFrameLocal.code
          },
          nextYearTimeFrames: {
            code: this.nextYearTimeFrameLocal.code
          },
          // Save SRE/SEE/AMA inside local
          schoolSreSeeUrl: this.schoolSreSeeUrl,
          sre: this.sreOptions,
          see: this.seeOptions,
          ama: this.amaOptions
        },
        y67t: this.y67tInfo
      }

      if (!this.schoolSreSeeUrl || this.schoolSreSeeUrl.length === 0) {
        delete settings.local.schoolSreSeeUrl
      }
      if (
        !this.sreOptions ||
        (Array.isArray(this.sreOptions) && this.sreOptions.length === 0)
      ) {
        delete settings.local.sre
      }
      if (
        !this.seeOptions ||
        (Array.isArray(this.seeOptions) && this.seeOptions.length === 0)
      ) {
        delete settings.local.see
      }
      if (
        !this.amaOptions ||
        (Array.isArray(this.amaOptions) && this.amaOptions.length === 0) ||
        (!this.sreOptions && !this.seeOptions)
      ) {
        delete settings.local.ama
      }

      if (this.y67tInfo === null) {
        delete settings.y67t
      }

      if (!this.isOOASchool && !this.school.y67t) {
        delete settings.ooa
      }

      await this.$store.dispatch('saveSettings', settings)
      await this.$store.dispatch('getSchoolOoaSettings', this.school.schoolCode)
      this.displaySaveCloseModal = false
      this.isPrincipalApproved = false
      this.closeAppSettingsModal()
    },

    async loadSavedSettings() {
      let savedSettings = null
      await this.$store
        .dispatch('getSavedSettings', this.school.schoolCode)
        .then((respData) => {
          if (respData && respData.isError) {
            this.showAlertErrorForUnableToRetrieveSettings = true
          } else {
            this.showAlertErrorForUnableToRetrieveSettings = false
            savedSettings = respData
          }
        })

      const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ]
      this.lastUpdatedBy = savedSettings ? savedSettings.lastUpdatedBy : null
      let lastUpdatedDateStr = savedSettings
        ? savedSettings.lastUpdatedDate
        : null

      if (lastUpdatedDateStr) {
        const lastUpdatedDateObj = new Date(
          lastUpdatedDateStr.replace(' ', '+')
        )
        this.lastUpdatedDate =
          lastUpdatedDateObj.getDate() +
          ' ' +
          months[lastUpdatedDateObj.getMonth()] +
          ' ' +
          lastUpdatedDateObj.getFullYear()
      }

      const settings = savedSettings ? savedSettings.customisation : null
      //const settings = savedSettings ? savedSettings : null
      if (settings) {
        //load y67t info
        this.y67tInfo = settings.y67t ? settings.y67t : null
        // load local enrolment settings
        const localSettings = settings?.local
        if (localSettings) {
          this.whoWillContact = localSettings.whoWillContact
          this.currentYearTimeFrameLocal = this.currentYearTimeFramesLocal.find(
            (t) => t.code === localSettings.currentYearTimeFrames.code
          )
          this.nextYearTimeFrameLocal = this.nextYearTimeFramesLocal.find(
            (t) => t.code === localSettings.nextYearTimeFrames.code
          )
          // load SRE, SEE and AMA settings
          this.schoolSreSeeUrl = localSettings.schoolSreSeeUrl
          if (localSettings.sre) {
            this.sreOptions = localSettings.sre
          }
          if (localSettings.see) {
            this.seeOptions = localSettings.see
          }
          if (localSettings.ama) {
            this.amaOptions = localSettings.ama
          }
        }

        // load ooa enrolment settings
        if (settings.ooa) {
          this.currentYearTimeFrame = this.currentYearTimeFrames.find(
            (t) => t.code === settings.ooa.currentYearTimeFrames.code
          )
          this.nextYearTimeFrame = this.nextYearTimeFrames.find(
            (t) => t.code === settings.ooa.nextYearTimeFrames.code
          )
          this.otherConsiderations = settings.ooa.enableOtherCriteria
          this.capacity = settings.ooa.capacity.code
          // match the criteria
          this.selectionCriteria = this.$store.getters.matchSchoolCriteria(
            settings.ooa.criteria
          )
        }
        return true
      } else {
        this.selectedValue = null
      }
      return false
    },

    async init() {
      // reset state
      Object.assign(this.$data, initialState())

      await this.$store.dispatch('getDefaultSreSeeOptions')

      if (
        !this.currentYearTimeFrames.length ||
        !this.currentYearTimeFramesLocal.length ||
        !this.nextYearTimeFrames.length ||
        !this.nextYearTimeFramesLocal.length ||
        !this.defaultSelectionCriteria.length ||
        !this.capacities
      ) {
        await this.$store.dispatch('getGlobalRefData')
      }

      await this.loadSavedSettings()

      // no settings saved. Load the defaults.
      const schoolOoaSettings = await this.$store.dispatch(
        'getSchoolOoaSettings',
        this.school.schoolCode
      )

      this.capacity = schoolOoaSettings.capacity
      // This was old code and I don't believe it will ever happen
      // Default to 'Approach' if prevCapStatus = 'NA' in oes-pilot-schools.json
      if (!this.capacity && this.prevCapStatus === 'NA') {
        this.capacity = 'Approach'
      }

      // load defaults for local enrolment if not exists in settings
      if (
        !this.whoWillContact &&
        !this.currentYearTimeFrameLocal &&
        !this.nextYearTimeFrameLocal
      ) {
        this.whoWillContact = this.$store.getters.whoWillContact
        this.currentYearTimeFrameLocal = this.currentYearTimeFramesLocal.find(
          (t) => t.default
        )
        this.nextYearTimeFrameLocal = this.nextYearTimeFramesLocal.find(
          (t) => t.default
        )
      }

      // load defaults for ooa enrolment if not exists in settings
      if (
        !this.currentYearTimeFrame &&
        !this.nextYearTimeFrame &&
        !this.selectionCriteria
      ) {
        this.currentYearTimeFrame = this.currentYearTimeFrames.find(
          (t) => t.default
        )
        this.nextYearTimeFrame = this.nextYearTimeFrames.find((t) => t.default)
        if (this.selectedValue) {
          this.selectionCriteria = this.newCriteriaFromDefault()
        } else {
          this.selectionCriteria = this.newSelectionCriteria()
        }
      }

      // save settings for dirty check
      this.originalSettings = this.getSettings()
      this.originalCriteria = this.selectionCriteria.map((c) => {
        return Object.assign({}, c) // make a copy
      })
      this.originalSchoolSreSeeUrl = this.schoolSreSeeUrl
      this.originalSeeOptions = this.seeOptions
      this.originalSreOptions = this.sreOptions
      this.originalAmaOptions = this.amaOptions

      // set Scout Capacity
      this.scoutCapacity = this.school.capStatus
      this.loaded = true

      this.$nextTick(function () {
        this.$refs.appSettingsModalPreFocus &&
          this.$refs.appSettingsModalPreFocus.focus()
      })
    }
  },
  mounted() {
    this.$eventHub.$on('duplicateSreOption', this.setDuplicateOption)
    this.$eventHub.$on('duplicateSeeOption', this.setDuplicateOption)
    this.$eventHub.$on('validateForm', this.showErrorsOrShowDialog(false))
  }
}
</script>

<style lang="scss">
.app-settings__header {
  background: $ads-navy;
  color: white !important;
  position: sticky; // sticky allows keyboard scroll when focused in the header
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 64px;
  z-index: 6;
  h1 {
    font-size: 1.25rem;
  }
  .v-btn {
    &:focus {
      border: 2px solid $color-focus !important;
    }
  }
}

.app-settings__header-ie {
  position: fixed; // ie-11 doesn't support sticky
}

.app-settings__content {
  color: $ads-navy;
  background: $ads-light-10;
  width: 100%;
  min-height: calc(100vh - 64px);
  position: absolute;
  top: 64px;
  h2 {
    font-size: 1.625rem;
    font-weight: 700;
    line-height: 1.875rem;
    margin-bottom: 0px;
  }
  p.subtitle {
    font-size: 1rem;
    color: $ads-navy;
  }
  .app-settings__circle-icon {
    border-radius: 50%;
    background: $ads-light-blue;
    color: $ads-navy;
    min-width: 54px;
    height: 54px;
    &.large {
      min-width: 116px;
      height: 116px;
    }
  }
  .app-settings__last-updated {
    font-style: italic;
    color: $ads-dark-70;
    font-size: 0.875rem;
    .v-icon {
      color: $ads-dark-70;
    }
  }
  .app-settings-heading {
    font-size: 1.25rem;
    font-weight: 700;
    color: $ads-navy;
  }
  .app-settings__subheading {
    font-size: 1rem;
    font-weight: 700;
    color: $ads-navy;
  }
  .app-settings__paragraph-text {
    max-width: 30rem;
  }
  .full-width {
    width: 100%;
    height: 39px;
    button {
      float: right;
    }
  }
  .sr-only {
    background-color: transparent;
  }
  .v-tabs {
    .v-tab {
      font-size: 18px;
      margin-bottom: 20px;
      letter-spacing: normal;
      padding-left: 60px;
      width: 292px;
      height: 67px;

      span {
        z-index: 1;
      }
    }
    .v-tabs-bar,
    .v-tabs-items {
      background: $ads-light-10;
    }
    .v-tab--active {
      background: $ads-white;
    }
  }
}

.v-application
  > .v-dialog__content
  > .v-dialog.v-dialog--active
  .v-card
  > .v-card__title
  > .title {
  font-size: 1.25rem !important;
}
.theme--light.v-btn:not(.v-btn--text):not(.v-btn--outlined):focus:before,
.theme--light.v-btn:focus::before {
  opacity: 0;
}
.theme--light.v-btn.settingsPrimary.primary:not(.v-btn--text):not(
    .v-btn--outlined
  ):focus {
  box-shadow: 0 0 0 4px $color-focus;
}
.theme--light.v-btn.settingsOutline.v-btn--outlined:focus {
  box-shadow: 0 0 0 4px $color-primary !important;
}
.v-list-item__title .v-input--checkbox.criteria .v-messages {
  display: none;
}
.v-alert__content .settings-link {
  margin: 0;
  text-decoration: underline;
  padding: 0 !important;
  height: 20px;
}

.settingsIcon.theme--dark.v-btn.v-btn--disabled {
  color: $color-btn-disabled-text-default !important;
  opacity: 1;
}

#adsDialogComponent .title {
  font-weight: bold;
  font-size: 28px !important;
  line-height: 34px;
  padding-top: 10px;
}

#adsDialogComponent .close-btn__wrapper .v-btn {
  border: none;

  &:focus {
    border: 2px solid $color-primary;
  }
}
.SREWrapper {
  max-width: 1360px;
  > div {
    &:not(:first-child) {
      margin-top: 1.5rem;
    }
    &:not(.v-alert) {
      padding: 27px 34px 40px;
    }
    margin-left: 9em;
    margin-right: 9em;
  }
}
.saveCloseBtn {
  display: flex;
  justify-content: flex-end;
  margin-right: 8.5rem;
}

.v-btn:focus:not(.v-btn--round):not(.v-btn--outlined):not(
    .v-btn--text
  ).primary:not(.app-settings__save-button) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
</style>
