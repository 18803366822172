var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-6 grey lighten-6"},[(_vm.isERNAdmin && !_vm.isUnlinkedStudentNotPopulated)?_c('v-row',{staticClass:"pb-3",attrs:{"justify":"end","align":"center","data-testid":"ps-action-btns"}},[(_vm.lastRefreshedDateTimeUnlinkedStudents)?_c('v-col',{staticClass:"pt-4"},[_c('span',[_vm._v("Last refreshed from ERN on "),_c('strong',[_vm._v(_vm._s(_vm.convertDateToDDMMMMYYYYatHHMM( new Date(_vm.lastRefreshedDateTimeUnlinkedStudents) ))+".")])])]):_vm._e(),_c('v-col',{attrs:{"cols":"auto"}},[_c('AdsButton',{ref:"refreshList",staticClass:"mr-0",attrs:{"button-text":"Refresh list","icon":"refresh"},on:{"click":_vm.openRefreshListModal}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('AdsButton',{ref:"exportList",staticClass:"mr-0",attrs:{"disabled":_vm.noUnlinkedStudents,"button-text":"Export list as CSV","icon":"list_alt"},on:{"click":_vm.openExportModal}})],1)],1):_vm._e(),_c('v-card',[_c('AdsDataTable',{attrs:{"headers":_vm.headers,"items":_vm.applications,"search-label":"Find student","search":_vm.internalSearch,"no-results-found":_vm.noResultsFoundText,"no-data-text":_vm.noResultsFoundText,"sort-by":"name","sort-asc":"","must-sort":"","items-per-page":_vm.pageLength,"footer-props":{
        'items-per-page-options': [10, 15, 50, -1]
      },"custom-filter":_vm.filterApplications,"page":_vm.currentPage,"data-testid":"y67tUnlinkedStudentsList"},on:{"update:page":[_vm.updatePagination,function($event){_vm.currentPage=$event}],"pagination":_vm.totalResults},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"row-line"},[_c('v-col',[_c('v-text-field',{staticClass:"searchInput",attrs:{"prepend-inner-icon":"mdi-magnify","label":"Find student","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.internalSearch),callback:function ($$v) {_vm.internalSearch=$$v},expression:"internalSearch"}})],1),_c('v-col',{staticClass:"results-text",attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(_vm.resultsText(_vm.itemsLength)))])])],1)]},proxy:true},{key:`item.parent`,fn:function({ item }){return [(
            item.familyContact.contactDetails &&
            item.familyContact.contactDetails.length > 0
          )?_c('div',[_c('div',{staticClass:"py-1"},[_vm._v("Family")])]):_vm._e(),_vm._l((item.parentCarers),function(parentCarer){return _c('div',{key:parentCarer.parentCarerGivenName,staticClass:"py-1"},[_vm._v(" "+_vm._s(parentCarer.parentCarerFamilyName)+", "+_vm._s(parentCarer.parentCarerGivenName)+" ")])})]}},{key:`item.email`,fn:function({ item }){return [(
            item.familyContact.contactDetails &&
            item.familyContact.contactDetails.length > 0
          )?_c('div',{staticClass:"py-1"},[(_vm.getFamilyEmail(item.familyContact.contactDetails))?_c('span',[_vm._v(_vm._s(_vm.getFamilyEmail(item.familyContact.contactDetails)))]):_c('span',[_vm._v(" ")])]):_vm._e(),_vm._l((item.parentCarers),function(parentCarer){return _c('div',{key:parentCarer.parentCarerGivenName,staticClass:"py-1"},[(parentCarer.checkERN)?_c('div',{staticClass:"checkERN"},[_c('v-icon',[_vm._v("mdi-alert-outline")]),_c('span',{staticClass:"srn-text"},[_vm._v("Check ERN to get contact details")])],1):_c('div',[_vm._v(" "+_vm._s(parentCarer.parentCarerEmail !== 'null' ? parentCarer.parentCarerEmail : ' ')+" ")])])})]}},{key:`item.phone`,fn:function({ item }){return [(
            item.familyContact.contactDetails &&
            item.familyContact.contactDetails.length > 0
          )?_c('div',{staticClass:"py-1"},[(_vm.getFamilyPhone(item.familyContact.contactDetails))?_c('span',[_vm._v(_vm._s(_vm.getFamilyPhone(item.familyContact.contactDetails)))]):_c('span',[_vm._v(" ")])]):_vm._e(),_vm._l((item.parentCarers),function(parentCarer){return _c('div',{key:parentCarer.parentCarerGivenName,staticClass:"py-1"},[(!parentCarer.checkERN)?_c('div',_vm._l((parentCarer.contactDetails),function(contact){return _c('div',{key:contact.contactValue},[_vm._v(" "+_vm._s(contact.contactValue)+" ")])}),0):_c('div')])})]}},{key:`item.action`,fn:function({ item }){return [_c('v-btn',{staticClass:"digitise-entrypoint-button",attrs:{"text":"","icon":"","small":"","aria-label":`Add new EOI or PNR for ${item.name}`},on:{"click":function($event){return _vm.openDigitiseEntryPointModal(item)}}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1)]}}],null,true)}),(_vm.showDigitiseEntryPointModal)?_c('Y67TDigitiseEntryPointModal',{attrs:{"selected-item":_vm.selectedItem,"data-testid":"digitise-entry-modal"},on:{"close":_vm.closeDigitiseEntryPointModal,"proceed":_vm.proceedApplication}}):_vm._e(),(_vm.displayApplicationModal)?_c('ApplicationModal',{attrs:{"type":_vm.applicationType,"item":_vm.selectedItem},on:{"close":_vm.closeApplicationModal}}):_vm._e(),_c('ExportListAsCsv',{directives:[{name:"show",rawName:"v-show",value:(_vm.showExportModal),expression:"showExportModal"}],attrs:{"applications":_vm.applicationsExportCsv,"file-name":_vm.csvFileName,"data-testid":"export-list-modal"},on:{"close":_vm.closeExportModal}}),_c('Y67TPSRefreshListModal',{directives:[{name:"show",rawName:"v-show",value:(_vm.showRefreshListModal),expression:"showRefreshListModal"}],attrs:{"data-testid":"refresh-list-modal"},on:{"close":_vm.closeRefreshListModal,"confirm":_vm.refreshUnlinkedStudents}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }