<template>
  <div>
    <div class="mx-6 mx-md-12 pt-4">
      <div class="d-flex align-start">
        <div
          class="app-settings__circle-icon d-flex align-center justify-center"
        >
          <v-icon size="30">playlist_add_check</v-icon>
        </div>
        <div class="ml-3 mt-3">
          <h3 class="app-settings-heading">Selection criteria</h3>
        </div>
      </div>
      <div>
        <div
          v-for="criteriaType in criteriaTypes"
          :key="criteriaType.type"
          class="pt-6 max-width-800"
        >
          <h4 class="app-settings__subheading pt-3">
            {{ criteriaType.type }} criteria
          </h4>
          <p class="pt-4 pb-4" v-html="criteriaType.description"></p>
          <v-card>
            <v-list class="py-0" data-testid="app-settings-criteria-list">
              <v-list-item
                v-for="(criteria, i) in criteriaType.criteria"
                :key="criteria.code"
                class="app-settings__criteria-row mr-0"
                :class="{ unselected: isUnselected(criteria) }"
                two-line
              >
                <v-list-item-content :class="{ locked: criteria.mandatory }">
                  <v-list-item-title class="d-flex pl-3 text-wrap">
                    <div
                      v-if="criteria.mandatory"
                      class="d-flex pt-4 pb-4"
                      tabindex="0"
                    >
                      <v-icon
                        class="mr-3 align-start"
                        role="img"
                        aria-label="position locked"
                        aria-hidden="false"
                        >mdi-lock-outline</v-icon
                      >
                      <span class="criteria__label">
                        {{ criteria.value }}
                      </span>
                    </div>
                    <OesCheckbox
                      v-if="!criteria.mandatory"
                      v-model="criteria.selected"
                      class="mr-3 criteria"
                      :aria-label="`${criteria.selected ? i + 1 : ''} -
                    ${criteria.value}`"
                      :id="getCriteriaId(criteria.code)"
                      @change="selectionChanged(i)"
                      :readonly="isSuperAdmin"
                      data-testid="app-settings-criteria-row-checkbox"
                    >
                      <template slot="label">
                        <span class="criteria__label">{{
                          criteria.value
                        }}</span>
                      </template>
                    </OesCheckbox>
                    <v-spacer />
                  </v-list-item-title>
                  <v-list-item-subtitle
                    class="app-settings__criteria-description"
                  >
                    <p
                      class="pl-11 pb-3 criteria__label"
                      v-if="criteria.code === 'STA'"
                    >
                      <i>See section 4.4 of the enrolment procedures</i>
                    </p>
                    <AdsTextArea
                      v-if="criteria.selected"
                      v-model="criteria.description"
                      label="Additional parent/carer instructions"
                      :outlined="true"
                      :rows="3"
                      :counter="250"
                      maxlength="250"
                      persistent-placeholder
                      hint="Text entered here will be shown to the parent when selecting this criteria."
                      persistent-hint
                      data-testId="app-settings-criteria-description"
                    />
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </div>
        <div class="max-width-800" :class="isY67THighSchoolOnly ? 'pt-6' : ''">
          <OesCheckbox
            v-if="!isY67THighSchoolOnly"
            @change="$emit('updatedOtherConsiderations', $event)"
            :input-value="otherConsiderations"
            class="app-settings__other-considerations-checkbox"
            data-testid="app-settings-other-considerations-checkbox"
            @click="trackOtherConsiderationsClick"
            :readonly="isSuperAdmin"
          >
            <template slot="label">
              <!-- eslint-disable -->
              <span
                >Enable &ldquo;<strong>Other considerations</strong>&rdquo;
                field in selections criteria form</span
              >
              <!-- eslint-enable -->
            </template>
          </OesCheckbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AdsTextArea } from '@nswdoe/doe-ui-core'
import OesCheckbox from '@/components/app/OesCheckbox'
import settingsUtils from '@/helpers/settingsUtils'
import { mapGetters } from 'vuex'

export default {
  name: 'AppSettingsSelectionCriteria',
  components: {
    AdsTextArea,
    OesCheckbox
  },
  props: {
    school: {
      type: Object,
      required: true
    },
    capacity: {
      type: String,
      default: null
    },
    selectionCriteria: {
      type: Array,
      default: () => []
    },
    otherConsiderations: {
      type: Boolean,
      default: null
    },
    selectedValue: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapGetters(['oesProperties']),
    isSuperAdmin() {
      return settingsUtils.isSuperAdmin()
    },
    isHighSchool() {
      return settingsUtils.isHighSchool(this.school)
    },
    isY67THighSchoolOnly() {
      return settingsUtils.isY67THighSchoolOnly(this.school)
    },
    mandatoryCriteria() {
      return this.selectionCriteria.filter((c) => c.mandatory === true)
    },
    nonMandatoryCriteria() {
      return this.selectionCriteria.filter((c) => c.mandatory === false)
    },
    criteriaTypes() {
      return [
        {
          type: 'Mandatory',
          description: `These criteria are mandatory for all schools, regardless of cap status, as listed in the order in <i>Section 4 - Managing Non-Local Enrolment</i> of the <a href="${this.oesProperties.urls.enrolmentKto12Procedures}" target="_blank"><b>Enrolment in Kindergarten to Year 12 Procedures</b></a>.`,
          criteria: this.mandatoryCriteria
        },
        {
          type: 'Non-mandatory',
          description: `Schools can choose to display or hide the following non-mandatory criteria, regardless of school cap, as listed in the order in <i>Section 4 - Managing Non-Local Enrolment</i> of the <a href="${this.oesProperties.urls.enrolmentKto12Procedures}" target="_blank"><b>Enrolment in Kindergarten to Year 12 Procedures</b></a>.`,
          criteria: this.nonMandatoryCriteria
        }
      ]
    }
  },
  methods: {
    getCriteriaId(code) {
      return `criteria-${code}`
    },
    isUnselected(criteria) {
      return !criteria.selected && !criteria.mandatory
    },
    selectionChanged(i) {
      const criteria = this.selectionCriteria[i]
      // eslint-disable-next-line vue/no-mutating-props
      this.selectionCriteria.splice(i, 1, criteria)
    },
    trackOtherConsiderationsClick() {
      this.$gtm.trackEvent({
        category: 'otherConsiderations',
        action: this.otherConsiderations,
        label: this.school.schoolName
      })
    }
  }
}
</script>

<style scoped lang="scss">
.max-width-800 {
  max-width: 800px;
}
.app-settings__criteria-row {
  min-height: 60px;
  &:not(:last-child) {
    border-bottom: 1px solid $ads-light-20;
  }
  &.unselected {
    background: $ads-light-10;
    .v-label {
      color: $ads-dark-80;
    }
  }
}

::v-deep .app-settings__criteria-description .v-text-field.v-textarea {
  max-width: 700px;
  margin-left: 44px;
  margin-top: 5px;
  .locked & {
    margin-top: 10px;
  }
  .v-label {
    font-weight: bold;
    font-size: 1.2rem;
  }
  textarea {
    color: $ads-dark-70;
  }
}

.app-settings__other-considerations-checkbox label span {
  color: $ads-dark;
}
.criteria__label {
  font-size: 1rem;
  color: $ads-dark;
}
</style>
