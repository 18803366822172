import API from '@/store/apiUtils'
import axios from 'axios'
import Vue from 'vue'

const settingsModule = {
  state: {
    whoWillContact: null,
    currentYearTimeFrames: [],
    currentYearTimeFramesLocal: [],
    nextYearTimeFrames: [],
    nextYearTimeFramesLocal: [],
    defaultSelectionCriteria: [],
    schoolOoaSettings: {},
    capacities: [],
    defaultSreSeeOptions: null
  },
  getters: {
    whoWillContact: (state) => state.whoWillContact,
    currentYearTimeFrames: (state) => state.currentYearTimeFrames,
    currentYearTimeFramesLocal: (state) => state.currentYearTimeFramesLocal,
    nextYearTimeFrames: (state) => state.nextYearTimeFrames,
    nextYearTimeFramesLocal: (state) => state.nextYearTimeFramesLocal,
    activeDeclinedReasons: (state) =>
      state.declinedReasons.filter((r) => r.active === true),
    activeWithdrawReasonsLocal: (state) =>
      state.withdrawReasonsLocal?.filter((r) => r.active === true),
    defaultSelectionCriteria: (state) => state.defaultSelectionCriteria,
    schoolOoaSettings: (state) => (schoolCode) =>
      state.schoolOoaSettings[schoolCode],
    matchSchoolCriteria: (state) => (schoolCriteria) => {
      const defaults = state.defaultSelectionCriteria
      if (schoolCriteria && defaults) {
        return defaults
          .filter((c) => c.active === true)
          .map((c) => {
            const matchedSetting = schoolCriteria.find(
              (sc) => sc.code === c.code
            )
            c.selected =
              c.mandatory === true || (matchedSetting && matchedSetting.enabled)
            c.description = matchedSetting ? matchedSetting.description : ''
            return c
          })
          .sort((c1, c2) => c1.defaultOrder - c2.defaultOrder)
      }
    },
    capacities: (state) => state.capacities,
    defaultSreSeeOptions: (state) => state.defaultSreSeeOptions
  },
  mutations: {
    setWhoWillContact(state, contact) {
      state.whoWillContact = contact || null
    },
    setCurrentYearTimeFrames(state, timeFrames) {
      state.currentYearTimeFrames = timeFrames || []
    },
    setCurrentYearTimeFramesLocal(state, timeFrames) {
      state.currentYearTimeFramesLocal = timeFrames || []
    },
    setNextYearTimeFrames(state, timeFrames) {
      state.nextYearTimeFrames = timeFrames || []
    },
    setNextYearTimeFramesLocal(state, timeFrames) {
      state.nextYearTimeFramesLocal = timeFrames || []
    },
    setDefaultSelectionCriteria(state, criteria) {
      state.defaultSelectionCriteria = criteria || []
    },
    setSchoolOoaSettings(state, settings) {
      const { schoolCode } = settings
      if (!state.schoolOoaSettings[schoolCode]) {
        // init the object
        // eslint-disable-next-line import/no-named-as-default-member
        Vue.set(state.schoolOoaSettings, schoolCode, [])
      }
      // eslint-disable-next-line import/no-named-as-default-member
      Vue.set(state.schoolOoaSettings, schoolCode, settings)
    },
    setCapacities(state, caps) {
      state.capacities = caps || []
    },
    setDeclinedReasons(state, declinedReasons) {
      state.declinedReasons = declinedReasons || []
    },
    setWithdrawReasonsLocal(state, withdrawReasons) {
      state.withdrawReasonsLocal = withdrawReasons || []
    },
    setDefaultSreSeeOptions(state, value) {
      state.defaultSreSeeOptions = value || {}
    }
  },
  actions: {
    getDefaultSreSeeOptions({ getters, commit }) {
      if (!getters.defaultSreSeeOptions) {
        return API.get(
          `${process.env.VUE_APP_REFDATA_API}/sre-see-options.json`
        ).then((response) => {
          commit('setDefaultSreSeeOptions', response.data)
        })
      }
      return Promise.resolve()
    },
    getGlobalRefData({ getters, commit }) {
      if (
        !getters.currentYearTimeFrames.length ||
        !getters.currentYearTimeFramesLocal.length ||
        !getters.nextYearTimeFrames.length ||
        !getters.nextYearTimeFramesLocal.length ||
        !getters.defaultSelectionCriteria.length
      ) {
        return API.get(
          `${process.env.VUE_APP_REFDATA_API}/global-web-content-ref.json`
        ).then((response) => {
          commit('setWhoWillContact', response.data.local.whoWillContact)
          commit(
            'setCurrentYearTimeFrames',
            response.data.ooa.currentYearTimeFrames
          )
          commit(
            'setCurrentYearTimeFramesLocal',
            response.data.local.currentYearTimeFrames
          )
          commit('setNextYearTimeFrames', response.data.ooa.nextYearTimeFrames)
          commit(
            'setNextYearTimeFramesLocal',
            response.data.local.nextYearTimeFrames
          )
          commit('setDefaultSelectionCriteria', response.data.ooa.criteria)
          commit('setCapacities', response.data.ooa.capacity)
          commit('setDeclinedReasons', response.data.ooa.declinedReasons)
          commit('setWithdrawReasonsLocal', response.data.local.withdrawReasons)
        })
      }

      return Promise.resolve()
    },
    getSavedSettings({ dispatch }, schoolCode) {
      // this will return a 403 error until the school saves their settings
      // for the first time.
      // use a separate axios instance to circumvent the default error handling in apiUtils
      const axiosInstance = axios.create()
      dispatch('showSpinner')
      return axiosInstance
        .get(
          `${process.env.VUE_APP_API_PI}/v1/ooa/schools/${schoolCode}/customisation`,
          {
            headers: { ...API.getHeaders(true), ...{ schoolCode } }
          }
        )
        .then((response) => {
          dispatch('hideSpinner')
          return response.data.body
        })
        .catch((error) => {
          dispatch('hideSpinner')
          if (error && error.response && error.response.status >= 500) {
            // INCASE - getSavedSettings function throws the error, then need to show alert to the use
            return {
              isError: true,
              message: 'Server Error'
            }
          }

          return null
        })
    },
    getSchoolSettingsJson(_, schoolCode) {
      // this api will return an error until the school saves their settings
      // for the first time.
      // use a separate axios instance to circumvent the default error handling in apiUtils
      const axiosInstance = axios.create()
      return axiosInstance
        .get(
          `${process.env.VUE_APP_REFDATA_API}/webCustomisation/${schoolCode}-web-customisation.json`,
          {
            headers: { schoolCode }
          }
        )
        .then((response) => {
          return response.data
        })
        .catch(() => {
          return null
        })
    },
    getSchoolOoaSettings({ commit, dispatch, getters, rootState }, schoolCode) {
      const promises = []
      promises.push(dispatch('getGlobalRefData'))
      promises.push(dispatch('getSavedSettings', schoolCode))

      return Promise.all(promises).then((responses) => {
        const schoolSettingsJson = responses[1]
          ? responses[1].customisation
          : undefined
        const school = rootState.schools.find(
          (s) => s.schoolCode === schoolCode
        )
        const schoolSettings = {
          schoolCode,
          capacity: school.capStatus
        }

        if (schoolSettingsJson) {
          if (schoolSettingsJson.ooa) {
            const { capStatus: scoutCapStatus } = getters.selectedSchool
            schoolSettings.criteria = getters
              .matchSchoolCriteria(schoolSettingsJson.ooa.criteria)
              .filter((c) => c.selected)
            schoolSettings.capacity =
              scoutCapStatus === 'NA'
                ? 'NA'
                : schoolSettingsJson.ooa.capacity.code
            schoolSettings.enableOtherCriteria =
              schoolSettingsJson.ooa.enableOtherCriteria
          }
          schoolSettings.savedSettings = true
        } else {
          // load defaults
          schoolSettings.criteria = getters.defaultSelectionCriteria.filter(
            (criteria) => criteria.mandatory && criteria.active // only include locked criteria by default
          )
          schoolSettings.savedSettings = false
        }
        commit('setSchoolOoaSettings', schoolSettings)
        return schoolSettings
      })
    },
    saveSettings({ rootState }, settings) {
      const school = rootState.schools.find(
        (s) => s.schoolCode === settings.schoolCode
      )

      Vue.gtm.trackEvent({
        category: 'settings',
        action: 'saveSettings',
        label: school ? school.schoolName : ''
      })

      return API.put(
        `${process.env.VUE_APP_API_PI}/v1/ooa/schools/${settings.schoolCode}/customisation`,
        settings,
        false,
        {
          schoolCode: settings.schoolCode
        }
      )
    }
  }
}

export default settingsModule
