<!--
  Provides the template for the yellow/green bar indicating SRN link status. If linked,
  the bar will turn green and the SRN will be displayed. If sent to ERN, the bar will
  change to reflect this.
-->
<template>
  <div>
    <Alert
      v-if="
        (isWithdrawn && !isWithdrawnRegStatusAndShsSubmission) ||
        (isWithdrawn &&
          isMeoApplication &&
          isWithdrawnRegStatusAndShsSubmission)
      "
      type="info"
      text="Withdrawn from submission"
      :subtext="`by ${application.lastModifiedUser || ''} on
        ${$moment(fieldValue('lastModifiedDatetime')).format('LLLL')}`"
      in-page
      icon="mdi-lock-outline"
      data-testid="withdrawn-status-bar"
      class="withdrawnAlert"
      role="none"
    />
    <Alert
      v-else-if="isWithdrawnRegStatusAndShsSubmission"
      type="info"
      text="Withdrawn in ERN"
      :subtext="`by ${application.lastModifiedUser || ''} on
        ${$moment(fieldValue('lastModifiedDatetime')).format('LLLL')}`"
      in-page
      icon="mdi-lock-outline"
      data-testid="withdrawn-status-bar"
      class="withdrawnAlert"
      role="none"
    />
    <Alert
      v-else-if="
        isEhubLinkedStudent && !isSentToErn && !isInvalid && !isInternalTransfer
      "
      type="success"
      :text="`Linked SRN: ${studentSrn || ''}`"
      in-page
      icon="link"
      role="none"
    />
    <template v-else-if="!isSentToErn && !isInvalid && !isInternalTransfer">
      <Alert
        v-if="isLinked"
        type="success"
        :text="`Linked SRN: ${fieldValue('student.srn')}`"
        in-page
        icon="link"
        :action-text="`${showUnlinkSrn ? 'UNLINK SRN' : ''}`"
        data-testid="local-link-status-bar"
        :action-handler="check"
        role="none"
      />
      <Alert
        v-if="!isLinked && !isSCEApplication"
        type="warning"
        text="SRN has not been matched"
        :subtext="`You need to check if ${fieldValue(
          'student.firstName'
        )} ${fieldValue('student.familyName')} has an existing SRN record.`"
        in-page
        action-text="CHECK"
        :action-handler="check"
        data-testid="local-new-status-bar"
        role="none"
      />
      <Alert
        v-if="!isLinked && isSCEApplication"
        type="warning"
        text="Support class enrolment"
        :subtext="formattedSubtext"
        in-page
        action-text="CHECK"
        :action-handler="check"
        data-testid="local-new-status-bar"
        class="in-page-alert--small"
        role="none"
      />
    </template>
    <Alert
      v-if="(isInvalid || isSentToErn) && !isWithdrawnRegStatusAndShsSubmission"
      type="success"
      :text="'Locked and sent to ERN'"
      :subtext="`by ${
        application.lastModifiedUser || fieldValue('lastModifiedDatetime')
      } on
        ${$moment(fieldValue('lastModifiedDatetime')).format('LLLL')}`"
      in-page
      icon="send"
      role="none"
    />
    <Alert
      v-if="
        (isInvalid || isSentToErn) &&
        !isWithdrawnRegStatusAndShsSubmission &&
        isSCEApplication
      "
      type="info"
      background-colour
      text="Support class enrolment"
      :subtext="'These details have been pre-filled with existing ERN data.'"
      in-page
      icon="mdi-information"
      data-testid="withdrawn-status-bar"
      class="in-page-alert--small"
      role="none"
    />
  </div>
</template>

<script>
import fieldHelperMixin from '@/helpers/fieldHelperMixin'
import { Alert } from '@nswdoe/doe-ui-core'
import { mapGetters } from 'vuex'
import { ERN_REGISTRATION_STATUS, ENROLMENT_TYPE, STATUS } from '@/constants'
export default {
  name: 'SrnStatusBar',
  mixins: [fieldHelperMixin],
  components: { Alert },
  computed: {
    ...mapGetters(['isEhubLinkedStudent', 'studentSrn']),
    isMeoApplication() {
      return this.application?.isMEOOffer
    },
    isLinked() {
      return !this.$store.getters.isCheckForSrn || this.isMeoApplication
    },
    application() {
      return this.$store.state.application
    },
    isY67TOfferedApp() {
      return !!this.application?.eoiID
    },
    isSCTApplication() {
      return this.application?.enrolmentType === ENROLMENT_TYPE.SCT
    },
    isSCEApplication() {
      return this.application?.enrolmentType === ENROLMENT_TYPE.SCE
    },
    isWithdrawnRegStatusAndShsSubmission() {
      const { WITPAR, WITSCH, WITCAN, WITDEC, WITNOC, WITLAP } =
        ERN_REGISTRATION_STATUS
      return (
        this.application?.enrolmentType === ENROLMENT_TYPE.SHS &&
        this.application?.applicationStatus === STATUS.WITHDRAWN &&
        [WITPAR, WITSCH, WITCAN, WITDEC, WITNOC, WITLAP].includes(
          this.$store.state.ernRecord?.registrationStatusCode || ''
        )
      )
    },
    showUnlinkSrn() {
      return (
        this.isLinked &&
        !this.isY67TOfferedApp &&
        !this.isSCTApplication &&
        !this.isMeoApplication
      )
    },
    formattedSubtext() {
      return `These details have been pre-filled with existing ERN data.
      Please confirm the details are correct with the parent/carer(s) before processing the application.`
    }
  },
  methods: {
    fieldValue(id) {
      // Safely returns values without failing if object is null
      try {
        return eval(`this.$store.state.application.${id}`)
      } catch (e) {
        return ''
      }
    },
    check() {
      if (this.isLinked) {
        this.$store.dispatch('set', ['openUnlinkSrnModal', true])
      } else {
        this.$store.dispatch('set', ['showSrnSearch', true])
      }
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .v-alert {
  margin: 0 3rem;
}

// Remove this chunk during ADS3 upgrade
::v-deep .v-alert__wrapper {
  margin: 5px 18px 0;
}

.withdrawnAlert {
  ::v-deep .v-alert__border {
    background-color: $color-placeholder !important;
    border-color: $color-placeholder !important;
  }

  ::v-deep .v-icon {
    color: $color-placeholder !important;
  }
}
</style>
